import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TripsRoutingModule } from './trips-routing.module';
import { TripsByCountComponent } from './trips/trips-by-count.component';
import { TripsByDateComponent } from './trips/trips-by-date.component';
import { TripsByUserComponent } from './trips/trips-by-user.component';
import { TripsByDateAllComponent } from './trips/trips-by-date-all.component';
import { TripsByCountAllComponent } from './trips/trips-by-count-all.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';



@NgModule({
  declarations: [
    TripsByCountComponent,
    TripsByDateComponent,
    TripsByUserComponent,
    TripsByDateAllComponent,
    TripsByCountAllComponent
  ],
  imports: [
    CommonModule,
    TripsRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatCardModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
  ]
})
export class TripsModule { }
