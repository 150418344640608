<mat-card>
 
<div *ngIf="apiLoaded | async">
    <google-map 
    height="500px"
    width="100%"
    [center]="center"
    [zoom]="zoom">
        <map-traffic-layer [autoRefresh]="false"></map-traffic-layer>
    </google-map>
  
</div>
<div>
    <ul *ngIf="myweather$ | async; let weather" class="list-group">
    <mat-grid-list cols="4" rowHeight="1:3">
        <mat-grid-tile>
            Temp.:  <br>
            Regn: <br>
            Fugt.: <br>
            Vind-ret.: <br>
            Vind-has.: <br>
            Gust: <br>
            For. regn: 
        </mat-grid-tile>    
        <mat-grid-tile>
            {{weather.properties.timeseries[0].data.instant.details.air_temperature}} <br>
            {{weather.properties.timeseries[0].data.instant.details.precipitation_rate}} <br>
            {{weather.properties.timeseries[0].data.instant.details.relative_humidity}}<br>
            {{weather.properties.timeseries[0].data.instant.details.wind_from_direction}} <br>
            {{weather.properties.timeseries[0].data.instant.details.wind_speed}} <br>
            {{weather.properties.timeseries[0].data.instant.details.wind_speed_of_gust}}<br>
            {{weather.properties.timeseries[0].data.next_1_hours.details.precipitation_amount}}<br>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
            <img class="image" mat-card-image src="{{getWeatherFileName()}}" alt="photo">
        </mat-grid-tile>
    </mat-grid-list>
</ul>
</div>
</mat-card>


<p>{{ location$ | async }}</p>


