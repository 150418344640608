import { TripByCountService } from "./../services/trip-by-count.service";
import { AuthService } from "./../../auth/services/auth.service";
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, of } from "rxjs";
import { TripByCount } from "../models/tripbycount";
import { switchMap, filter } from "rxjs/operators";
import { MatCardModule } from "@angular/material/card";
import { CookieService } from "ngx-cookie-service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-trips",
  templateUrl: "./trips-by-count.component.html",
  styleUrls: ["./trips-by-count.component.scss"],
})
export class TripsByCountComponent implements OnInit {
  tripsByCount$: Observable<TripByCount[]>;
  displayedColumns: string[] = [
    "driver",
    "passenger1",
    "passenger2",
    "passenger3",
    "passenger4",
    "count",
    "date",
  ];
  fileName = "TripsByCount.xlsx";
  appUserAgentCheck: Boolean;
  myFirstGroup$: Observable<string>;
  myFirstGroup2$: Observable<string>;

  isLoading = true;
  constructor(
    private tripsByCountService: TripByCountService,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    console.log("ngOn");
    this.cookieService.set("last-page-visited", "countbyuser");
    const userAgent = window.navigator.userAgent;
    this.appUserAgentCheck = userAgent.includes("carpoolcommute");
    console.log(this.appUserAgentCheck);
    //console.log(this.tripsByCountService.tripbycount$)
    this.myFirstGroup$ = this.authService.myfirstgroup$;
    console.log(this.authService.myfirstgroup$);
    //this.tripsByCount$ = this.tripsByCountService.tripbycount$;
    this.getTripCount();
  }

  public getTripCount() {
    this.authService.myfirstgroup$
      .pipe(switchMap((group) => this.tripsByCountService.tripbycount$(group)))
      .subscribe((res) => {
        this.isLoading = false;
        this.tripsByCount$ = of(res);
      });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}
