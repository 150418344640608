import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';

const routes: Routes = [{
  path: 'home', component: HomeComponent
}];

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [RouterModule.forChild(routes), BrowserModule,MatCardModule],
  exports: [RouterModule] 
})
export class HomeModule {

  constructor(

  ) {

  }

}
