<mat-card>
  <table mat-table id="excel-table" [dataSource]="dataSource" matSort>
    <!-- <table mat-table id="excel-table" [dataSource]="tripsByDate$"> -->
    <ng-container matColumnDef="carname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><mat-icon class="icon passenger" >directions_car</mat-icon></th>
      <td mat-cell *matCellDef="let element" (click)="getRecord(element)"> {{element.carname}} </td> 
    </ng-container>
    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >add_task</mat-icon></th>
      <td mat-cell *matCellDef="let element"> {{element.count}} </td>
    </ng-container>
    <ng-container matColumnDef="first_event_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><mat-icon class="icon passenger" >schedule</mat-icon></th>
      <td mat-cell *matCellDef="let element"> {{element.first_event_date}} </td>
    </ng-container>
    <ng-container matColumnDef="first_event_time">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >schedule</mat-icon>start</th>
      <td mat-cell *matCellDef="let element"> {{element.first_event_time}} </td>
    </ng-container>
    <ng-container matColumnDef="last_event_time">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >schedule</mat-icon>stop</th>
      <td mat-cell *matCellDef="let element"> {{element.last_event_time}} </td>
    </ng-container>
    
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>
</mat-card>

<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner diameter="300"
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>




