import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule, Title } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { environment } from "src/environments/environment";
import { UsersModule } from "./users/users.module";
import { TripsModule } from "./trips/trips.module";
import { SettingsModule } from "./settings/settings.module";
import { HomeModule } from "./home/home.module";
import { LoginModule } from "./login/login.module";
import { AboutModule } from "./about/about.module";
import { PrivacyModule } from "./privacy/privacy.module";
import { SupportModule } from "./support/support.module";
import { NewUserModule } from "./new-user/new-user.module";
import { EditSettingsModule } from "./edit-settings/edit-settings.module";
import { GoogleMapsTrafficModule } from "./traffic/google-maps-traffic.module";
import { GroupsModule } from "./groups/groups.module";
import { HelpModule } from "./help/help.module";
import { CleverModule } from "./clever/clever.module";
import { PowerModule } from "./power/power.module";
import { ForecastModule } from "./forecast/forecast.module";
import { LoginComponent } from "./login/login/login.component";
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from "@angular/forms";
import { AuthTokenHttpInterceptorProvider } from "./http-interceptors/auth-token.interceptor";
import { ErrorHandlerHttpInterceptorProvider } from "./http-interceptors/http-error.interceptor";
import { AngularMaterialModule } from "./angular-material/angular-material.module";
import { TripsByCountComponent } from "./trips/trips/trips-by-count.component";
import { TripsByDateComponent } from "./trips/trips/trips-by-date.component";
import { TripsByUserComponent } from "./trips/trips/trips-by-user.component";
import { TripsByDateAllComponent } from "./trips/trips/trips-by-date-all.component";
import { SettingsCombinedComponent } from "./settings/settings/settings-combined.component";
import { HomeComponent } from "./home/home/home.component";
import { SupportComponent } from "./support/support/support.component";
import { HelpComponent } from "./help/help/help.component";
import { GroupsComponent } from "./groups/groups/groups.component";
import { AboutComponent } from "./about/about/about.component";
import { PrivacyComponent } from "./privacy/privacy/privacy.component";
import { CookieService } from "ngx-cookie-service";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogConfig } from "@angular/material/dialog";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
//import { LoginComponent } from "./login/login/login.component";
//import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog/confirmation-dialog.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { EditSettingsComponent } from "./edit-settings/edit-settings/edit-settings.component";
import { AddTripComponent } from "./add-trip/add-trip/add-trip.component";
import { NewUserComponent } from "./new-user/new-user/new-user.component";
import { GoogleMapsTrafficComponent } from "./traffic/traffic/google-maps-traffic.component";
//import { TrafficComponent } from './traffic/traffic.component';
//import { GoogleMapsModule } from '@angular/google-maps'
//import { UserFormComponent2 } from './users/user-form/user-form2.component';
//import { NgModule } from '@angular/core';
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatChipsModule } from "@angular/material/chips";

import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from "ngx-cookieconsent";
import { ServerSettingsComponent } from "./server-settings/server-settings.component";
import { CleverComponent } from "./clever/clever/clever.component";
import { ForecastComponent } from "./forecast/forecast/forecast.component";
import { PowerComponent } from "./power/power/power.component";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: "localhost", // or 'your.domain.com'
  },
  palette: {
    popup: {
      background: "#000",
    },
    button: {
      background: "#f1d600",
    },
  },
  theme: "edgeless",
  type: "opt-out",
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EditSettingsComponent,
    AddTripComponent,
    NewUserComponent,
    ServerSettingsComponent,
    //PowerComponent,
    //ForecastComponent,
    //CleverComponent,
    //TrafficComponent,
    //UserFormComponent2,
    //ConfirmationDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    //FormsModule,
    MatDialogModule,
    MatCheckboxModule,
    BrowserModule,
    //BrowserModule,
    RouterModule.forRoot(
      [
        { path: "home", component: HomeComponent },
        { path: "login", component: LoginComponent },
        { path: "countbyuser", component: TripsByCountComponent },
        { path: "countbydate", component: TripsByDateComponent },
        { path: "countbydate/:id", component: TripsByDateComponent },
        { path: "countbymyuser", component: TripsByUserComponent },
        { path: "download", component: TripsByDateAllComponent },
        { path: "download/:id", component: TripsByDateAllComponent },
        { path: "groups", component: GroupsComponent },
        { path: "combinedsettings", component: SettingsCombinedComponent },
        { path: "combinedsettings/:id", component: SettingsCombinedComponent },
        { path: "serversettings/:id", component: ServerSettingsComponent },
        { path: "about", component: AboutComponent },
        { path: "privacy", component: PrivacyComponent },
        { path: "support", component: SupportComponent },
        { path: "help", component: HelpComponent },
        { path: "traffic", component: GoogleMapsTrafficComponent },
        { path: "clever", component: CleverComponent },
        { path: "powerusage", component: PowerComponent },
        { path: "forecast", component: ForecastComponent },
        { path: "**", redirectTo: "home", pathMatch: "full" },
      ],
      { relativeLinkResolution: "legacy" }
    ),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    HttpClientModule,
    GoogleMapsTrafficModule,
    UsersModule,
    NewUserModule,
    EditSettingsModule,
    TripsModule,
    SettingsModule,
    HomeModule,
    AboutModule,
    PrivacyModule,
    SupportModule,
    HelpModule,
    LoginModule,
    GroupsModule,
    //NgbModule,
    ClipboardModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    CleverModule,
    PowerModule,
    ForecastModule,
    MatChipsModule,
  ],
  providers: [
    AuthTokenHttpInterceptorProvider,
    ErrorHandlerHttpInterceptorProvider,
    CookieService,
    Title,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
