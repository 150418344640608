import { TripByDateService } from "./../services/trip-by-date.service";
import { AuthService } from "./../../auth/services/auth.service";
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, of } from "rxjs";
import { TripByDate } from "../models/tripbydate";
import { switchMap, filter } from "rxjs/operators";
import { MatCardModule } from "@angular/material/card";
import { CookieService } from "ngx-cookie-service";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as XLSX from "xlsx";
import { ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-trips",
  templateUrl: "./trips-by-date.component.html",
  styleUrls: ["./trips-by-date.component.scss"],
})
export class TripsByDateComponent implements OnInit {
  tripsByDate$: Observable<TripByDate[]>;
  tripsByDateAll$: Observable<TripByDate[]>;
  fileName = "Last10TripsByDate.xlsx";

  displayedColumns: string[] = [
    "driver",
    "passenger1",
    "passenger2",
    "passenger3",
    "passenger4",
    "date",
  ];

  myFirstGroup$: Observable<string>;
  appUserAgentCheck: Boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();

  isLoading = true;
  id: string;

  constructor(
    private route: ActivatedRoute,
    private tripsByDateService: TripByDateService,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private cookieService: CookieService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    console.log("ngOn tripsbydate");
    this.id = this.route.snapshot.paramMap.get("id");
    console.log(this.id);
    this.cookieService.set("last-page-visited", "countbydate");
    const userAgent = window.navigator.userAgent;
    this.appUserAgentCheck = userAgent.includes("carpoolcommute");
    console.log(this.appUserAgentCheck);
    //console.log(this.tripsByCountService.tripbycount$)
    this.myFirstGroup$ = this.authService.myfirstgroup$;
    console.log(this.authService.myfirstgroup$);
    //this.tripsByCount$ = this.tripsByCountService.tripbycount$;
    if (this.id == null) {
      console.log("logged in groups");
      this.getThisUserTripsDateAll();
    } else {
      console.log("Admin groups");
      this.getTripsDateAll2(this.id);
    }
    let tableCell = document.querySelector("td");
    let cellWidth = tableCell.clientWidth + "px";
    let cellHeight = tableCell.clientHeight + "px";
    console.log("width: " + cellWidth, " / height: " + cellHeight);
  }

  public getTripDate() {
    this.authService.myfirstgroup$
      .pipe(switchMap((group) => this.tripsByDateService.tripbydate$(group)))
      .subscribe((res) => {
        this.isLoading = false;
        this.dataSource.data = res;
        //console.log(this.dataSource.data)

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 0);

        this.tripsByDate$ = of(res);
      });
  }

  public getThisUserTripsDateAll() {
    this.authService.myfirstgroup$
      .pipe(
        switchMap((group) => this.tripsByDateService.alltripsbydate$(group))
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.dataSource.data = res;
        //console.log(this.dataSource.data)

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 0);
        this.tripsByDateAll$ = of(res);
      });
  }

  public getTripsDateAll() {
    this.authService.myfirstgroup$
      .pipe(
        switchMap((group) => this.tripsByDateService.alltripsbydate$(group))
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.dataSource.data = res;
        //console.log(this.dataSource.data)

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 0);
        this.tripsByDateAll$ = of(res);
      });
  }

  public getTripsDateAll2(group: string) {
    this.tripsByDateService
      .alltripsbydate$(group)
      .pipe()
      .subscribe((res) => {
        this.isLoading = false;
        this.dataSource.data = res;
        //console.log(this.dataSource.data)

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 0);
        this.tripsByDateAll$ = of(res);
      });
  }

  getRecord(row) {
    console.log(row);
    let message = "Start: " + row.starttime + "\nStop: " + row.endtime;
    this._snackBar.open(message, $localize`Undo`, {
      duration: 5000,
      verticalPosition: "top",
    });
  }

  getCarRecord(row) {
    console.log(row);
    let message = row.carmodel + "\n:" + row.licenseplate;
    this._snackBar.open(message, $localize`Undo`, {
      duration: 5000,
      verticalPosition: "top",
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}
