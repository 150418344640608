import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { SettingsGroup } from "../../settings/models/settingsGroup";
import { Weather } from "../models/weather";
import { SettingsService } from "../../settings/services/settings.service";
import { TrafficService } from "../services/traffic.service";
import { SettingsMy } from "../../settings/models/settingsMy";
import { UserService } from "./../../users/services/user.service";
import { AuthService } from "./../../auth/services/auth.service";
import { User } from "./../../users/models/user";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { switchMap, filter, concatMap } from "rxjs/operators";

@Component({
  selector: "google-maps-traffic",
  templateUrl: "./google-maps-traffic.component.html",
})
export class GoogleMapsTrafficComponent implements OnInit {
  apiLoaded: Observable<boolean>;
  settingsMy$: Observable<SettingsMy[]>;
  settingsGroup$: Observable<SettingsGroup[]>;
  user$: Observable<User>;
  location$: Observable<string>;
  myweather$: Observable<Weather[]>;

  /*
  options: google.maps.MapOptions = {
    center: {lat: 56.1077384778227, lng: 10.13856874419218},
    zoom: 12
  };
  */
  center: google.maps.LatLngLiteral = {
    lat: 56.1077384778227,
    lng: 10.13856874419218,
  };
  zoom = 12;
  currentEnd = ".svg";
  weatherFileType = "";
  // Slet lat: 56.1077384778227, lng: 10.13856874419218
  // Aabybro lat: 57.160205, lng: 9.734749
  constructor(
    httpClient: HttpClient,
    private userService: UserService,
    private afAuth: AngularFireAuth,
    private settingsService: SettingsService,
    private trafficService: TrafficService
  ) {
    this.apiLoaded = httpClient
      .jsonp(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBteewj0ascOCf33SZr_HH-SxH52qP6pJc",
        "callback"
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  ngOnInit(): void {
    console.log("ngOn google Maps");
    //this.getTrafficInfo();
    //this.getWeather();
  }

  public getWeather(location: any) {
    console.log("getWeather()");
    console.log(location);
    this.trafficService
      .weather$(location.lng, location.lat)
      .pipe(
        map((val) => {
          console.log(val.properties.timeseries[0].data.instant.details);
          this.weatherFileType =
            val.properties.timeseries[0].data.next_1_hours.summary.symbol_code;
          this.myweather$ = of(val);
          //.properties.timeseries[0].data.instant.details)
        })
      )
      .subscribe();
  }

  getWeatherFileName() {
    console.log("getWeatherFileName");
    //console.log(weatherType);
    let filePath = "assets/weather/svg/";
    let fileNameExtension = ".svg";
    let fileName = filePath + this.weatherFileType + fileNameExtension;
    return fileName;
  }
  /*
  public getTrafficInfo() {
    this.settingsService.settingsgroup$.pipe(
      map((val) => {
        val.forEach(val => {
        if (val.CurrentUser == val.UserId)
        {
         console.log(val.TrafficLocation)
         var splitstring = val.TrafficLocation.split(',')
         console.log(splitstring)
         var location = {lat: parseFloat(splitstring[0]), lng: parseFloat(splitstring[1])};
         this.center = location;
         console.log(this.center)
         this.location$ = of(val.TrafficLocation);
         this.getWeather(this.center)
         return val.TrafficLocation
        }
         });
      })     
    ).subscribe()

  }
  */
}
