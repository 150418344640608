<mat-card id="card" style="text-align: center;">
  
<div *ngIf="user$ | async; let auser">
: {{auser.customClaims.groups}}
</div>


<mat-card-title><span i18n>Power consumption:</span>{{month}}</mat-card-title>
<mat-card class="my-class-name asd cardPardding" id="card" style="text-align: center;">
    <mat-grid-list cols="4">
        <mat-grid-tile>
            <p matLine><span i18n>Month Amount:</span>
            {{tPower}} <span i18n>Kwh</span></p>
        </mat-grid-tile>
        <mat-grid-tile>
            <p matLine><span i18n>Month Cost:</span>
            {{tCost}} <span i18n>Kr.</span></p>
        </mat-grid-tile>
        <mat-grid-tile>
            <p matLine><span i18n>Average Kwh Cost:</span>
                {{average}} <span i18n>Kr.</span></p>
        </mat-grid-tile>
        <mat-grid-tile>
            <ng-container>
            <p class="container" style="width:60%; height: 60%; ">
                <!-- <a style="cursor: pointer" (click)="exportexcel()"> -->
                    <!-- <mat-icon> cloud_download</mat-icon>Download Excel -->
                    <img class="image" mat-card-image src="assets/ev_charger.svg" alt="photo">
             <!--   </a> -->
            </p>
            </ng-container> 
        </mat-grid-tile>
    </mat-grid-list>

</mat-card>

<mat-card class="my-class-name asd cardPardding" id="card" style="text-align: center;">
<table mat-table id="excel-table" [dataSource]="powerData$">
    
<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    <ng-container mat-header-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef><span i18n>Time</span></th>
        <td mat-cell *matCellDef="let element"> {{element.Time | date:'MMM d, HH:mm':'CET'}} </td>

    </ng-container>
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef><span i18n>Amount</span> Kwh</th>
    <td mat-cell *matCellDef="let element"> {{element.Power}} </td>

    </ng-container>
    
    <ng-container matColumnDef="spotPrice">
        <th mat-header-cell *matHeaderCellDef><span i18n>Spot Price</span> kr/Kwh</th>
    <td mat-cell *matCellDef="let element"> {{element.SpotPriceDK1}} </td>

    </ng-container>
    <ng-container mat-header-container matColumnDef="netTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>NetTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.NetTarif}} </td>
    </ng-container>
        <ng-container mat-header-container matColumnDef="elAfgift">
            <th mat-header-cell [hidden]="true"  *matHeaderCellDef><span i18n>Elafgift</span></th>
            <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.Elafgift}} </td>
        </ng-container>
    <ng-container mat-header-container matColumnDef="balanceTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>BalanceTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.BalanceTarif}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="systemTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>SystemTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.SystemTarif}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="transTarif">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>TransTarif</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.TransTarif}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="extraTotal">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>ExtraTotal</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.ExtraTotal}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="expense">
        <th mat-header-cell [hidden]="true" *matHeaderCellDef><span i18n>Expense</span></th>
        <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.Expense}} </td>
    </ng-container>
    <ng-container mat-header-container matColumnDef="totalCost">
        <th mat-header-cell  *matHeaderCellDef><span i18n>Total Cost</span></th>
        <td mat-cell  *matCellDef="let element"> {{element.TotalCost}} </td>
    </ng-container>

</table>



</mat-card>
