import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TripByCount } from '../models/tripbycount';
import SettingsJson from '../../../assets/appsettings.json';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TripByCountService {

  constructor(
    private http: HttpClient
    
  ) {
    console.log("constructor: ")
   }
  tripbycount$(url_param: string): Observable<TripByCount[]> { 
    let baseUrl = environment.userApi
    let tripByCountBaseUrl = baseUrl + SettingsJson.tripByCountUrl; 
    console.log("tripByCountBaseUrl: " + tripByCountBaseUrl)
    return this.http.get<{trips: TripByCount[]}>(`${tripByCountBaseUrl}=${url_param}`).pipe(
      tap(val => console.log(`tripByCount BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result.trips)
        return result.trips;
      })
    );
  }


  
}
