import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { of, from} from 'rxjs';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  secondThemeSelected : boolean = true;
  isDone = false;

  appType$: Observable<string>;
  appMode$: Observable<string>;

  constructor() { }


  ngOnInit(): void {
    var userAgent = navigator.userAgent;
    console.log("Useragent: " + userAgent);
    let appType = environment.app_type
    let appMode = environment.app_mode
    console.log(appType)
    console.log(appMode)
    this.appType$ = of(appType);
    this.appMode$ = of(appMode);

    if (userAgent.includes("carpoolcommute"))
    {
      console.log("carpoolcommute app:  ");
      this.secondThemeSelected = false;

      if (userAgent == "carpoolcommute1")
      {
        this.secondThemeSelected = false;
      }

      if (userAgent == "carpoolcommute2")
      {
        this.secondThemeSelected = true;
      }
    }
    else
    {
      console.log("Not carpoolcommute app: ");
      this.secondThemeSelected = false;
    }
  }

}
