import { SettingsService } from "./../services/settings.service";
import { UserService } from "./../../users/services/user.service";
//import { AuthService } from './../../auth/services/auth.service';
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { SettingsGroup } from "../models/settingsGroup";
import { SettingsGroup2 } from "../models/settingsGroup";
import { Tokens } from "../models/tokens";
import { SettingsMy } from "../models/settingsMy";
import { switchMap, filter } from "rxjs/operators";
//import { MatCardModule } from '@angular/material/card';
//import { MatButtonModule } from '@angular/material/button';
import { User } from "./../../users/models/user";
//import { UserFormService } from './../../users/services/user-form.service';
//import { UserFormComponent } from './../../users/user-form/user-form.component';
//import { UserFormEditUserComponent } from './../../users/user-form/user-form-edit-user.component';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { EditSettingsComponent } from "../../edit-settings/edit-settings/edit-settings.component";
import { AddTripComponent } from "../../add-trip/add-trip/add-trip.component";
import SettingsJson from "../../../assets/appsettings.json";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
//import { settings } from "cluster";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-setting",
  templateUrl: "./settings-combined.component.html",
  styleUrls: ["./settings-combined.component.scss"],
})
export class SettingsCombinedComponent implements OnInit {
  //settingsGroup$: Observable<SettingsGroup[]>;
  userTokens$: Observable<Tokens[]>;
  //userToken$: Observable<Tokens[]>;
  settingsGroup$: Observable<SettingsGroup[]>;
  settingsMy$: Observable<SettingsMy[]>;
  adminUser$: Observable<boolean>;
  user$: Observable<User>;
  appType$: Observable<string>;
  id: string;
  myUser: string;
  settingsGroupStatic: any[];

  copyClipText = "Hello, Copy this text.";

  displayedColumns: string[] = [
    "GroupName",
    "Name",
    "ShortName",
    "UuidMajor",
    "Email",
  ];

  isLoading = true;

  tableDef: Array<any> = [
    {
      key: "Name",
      header: "Name",
      className: "something",
    },
    {
      key: "ShortName",
      header: "Short Name",
      className: "anElement",
    },
    {
      key: "GroupName",
      header: "Group Name",
      className: "number",
    },
    {
      key: "UuidMajor",
      header: "Uuid Major",
      className: "number",
    },
    {
      key: "Email",
      header: "Email",
      className: "number",
    },
  ];

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    //private authService: AuthService,
    private afAuth: AngularFireAuth,
    //private userForm: UserFormService,
    //private modal: NgbModal,
    private _router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    console.log("ngOn Settings combined");
    this.id = this.route.snapshot.paramMap.get("id");
    console.log(this.id);
    let appType = environment.app_type;
    console.log(appType);
    this.appType$ = of(appType);
    this.cookieService.set("last-page-visited", "settings");

    this.user$ = this.afAuth.user.pipe(
      filter((user) => !!user),
      switchMap((user) => this.userService.user$(user.uid))
    );

    // this.settingsGroup$ = this.settingsService.settingsgroup$;

    if (this.id == null) {
      console.log("logged in groups");
      this.id = "";
    } else {
      console.log("Admin groups");
    }

    this.userTokens$ = this.settingsService.usertokens$;
    this.getSettings(this.id, appType);
    // this.getSettings2(this.id, appType);
    this.getData();
    //this.settingsService.getConditionalDataUsingAsync(this.id);
    console.log("ttttteste:");

    //this.settingsGroupStatic = this.getSettings(this.id, appType);
  }

  async getData() {
    this.settingsGroupStatic =
      await this.settingsService.getSettingsGroupDataUsingAsync(this.id);
    /*
    let test2 = await this.settingsService.getSettingsGroupDataUsingAsync(
      this.id
    );
    console.log("11111:");
    console.log(test1);
    console.log(test2);
    console.log(test1);
    */
  }

  reloadComponent(id: string) {
    console.log("reloadComponent:");
    console.log(id);
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/combinedsettings", { id: id }]);
  }

  create() {
    let group = "test1";
    let myUser = {
      Active: "1",
      Options: "1",
      CurrentUser: "NA",
      Email: "magnus.mikkelsen@porsevej.dk",
      Include: "0",
      Name: "Magnus Mikkelsen",
      SendEmail: "0",
      ShortName: "MM",
      UserId: "EvLzHL1fvVO9zN4Z4izrg8IL9Mh1",
      Uuid: "1c4e0347-a8df-41a2-86ac-74ac19713d02",
      TrafficLocation: "-34.397, 150.644",
      LicensePlate: "YY77777",
      CarModel: "Peugeot 308",
      UuidMajor: "6",
    };

    this.settingsService.CreateNewUserSetting(myUser, group).subscribe((_) => {
      console.log("user created");
    });
  }

  public getSettings(group: string, appType: string) {
    console.log("getSettings !!");
    let myArray = [];
    this.settingsService
      .settingsgroup$(group)
      .pipe(
        map((val) => {
          val.forEach((val2) => {
            if (group == "") {
              if (appType == "cartrack") {
                if (val2.CurrentUser == val2.UserId) {
                  myArray.push(val2);
                } else {
                  if (val2.Options == "2") {
                    myArray.push(val2);
                  }
                }
              } else {
                myArray.push(val2);
              }
            } else {
              myArray.push(val2);
            }
          });
          this.isLoading = false;
          this.settingsGroup$ = of(myArray);
        })
      )
      .subscribe((val) => console.log("at Subscriber " + JSON.stringify(val)));
    return myArray;
  }
  /*
  public getSettings2(group: string, appType: string) {
    console.log("getSettings2 !!");
    this.settingsService.fetchData();
    let myArray = [];
  }
 */
  openEditDialog(userToEdit): void {
    console.log("userToEdit: " + JSON.stringify(userToEdit));
    let dialogRef = this.dialog.open(EditSettingsComponent, {
      minWidth: "20vw",
      data: {
        userid: userToEdit.UserId,
        groupid: userToEdit.GroupId,
        displayname: userToEdit.Name,
        shortname: userToEdit.ShortName,
        usertype: userToEdit.Options,
        uuidmajor: userToEdit.UuidMajor,
        trafficlocation: userToEdit.TrafficLocation,
        licenseplate: userToEdit.LicensePlate,
        carmodel: userToEdit.CarModel,
        batterychangedate: userToEdit.BatteryChangeDate,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Saved2 result: " + JSON.stringify(result));
      if (typeof result !== "undefined") {
        this.settingsService
          .editUserSetting(
            result.UserId,
            result.GroupId,
            result.ShortName,
            result.UuidMajor,
            result.TrafficLocation,
            result.LicensePlate,
            result.CarModel,
            result.BatteryChangeDate
          )
          .subscribe((_) => {
            console.log("setting edited");
            this.reloadComponent(this.id);
          });
      }
    });
  }

  openAddTripDialog(userToEdit): void {
    console.log(this.settingsGroupStatic);
    console.log("openAddTripDialog userToEdit: " + JSON.stringify(userToEdit));
    let dialogRef = this.dialog.open(AddTripComponent, {
      minWidth: "20vw",
      data: {
        carid: userToEdit.CarId,
        userid: userToEdit.UserId,
        groupid: userToEdit.GroupId,
        displayname: userToEdit.Name,
        shortname: userToEdit.ShortName,
        usertype: userToEdit.Options,
        licenseplate: userToEdit.LicensePlate,
        carmodel: userToEdit.CarModel,
        passengernames: this.settingsGroupStatic,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Saved AddTrip result: " + JSON.stringify(result));

      if (typeof result !== "undefined") {
        this.settingsService
          .CreateTrip(result, userToEdit.GroupId)
          .subscribe((result) => {
            console.log("æææææææææææ");
            console.log(result);
            let message: string = "";

            if (result.passengerResult == "no_conflict") {
              message = $localize`Trip settings saved`;
            } else {
              message = $localize`Conflict !!!, Trip settings NOT saved`;
            }

            console.log(message);
            this._snackBar.open(message, $localize`Undo`, {
              duration: 5000,
              verticalPosition: "top",
            });
            //this.reloadComponent(this.id);
          });
      }
    });
  }

  newToken(userToEdit) {
    console.log("new token: " + JSON.stringify(userToEdit));

    let myData = {
      urlBackend: environment.userApi,
      urlFrontend: environment.frontEndApi,
      serverName: environment.serverName,
      group: userToEdit.GroupId,
      userid: userToEdit.UserId,
    };
    this.settingsService.CreateNewUserToken(myData).subscribe((_) => {
      console.log("New Token created");
      this.reloadComponent(this.id);
    });
  }

  emailToken(userToEdit) {
    console.log("email token: " + JSON.stringify(userToEdit.Email));

    let myData = {
      serverName: environment.serverName,
      group: userToEdit.GroupId,
      userid: userToEdit.UserId,
      email: userToEdit.Email,
      name: userToEdit.Name,
    };
    this.settingsService.createTokenEmail(myData).subscribe((result) => {
      console.log("Token Email sent");
      console.log(result);
      let message: string = "";
      //Todo fix
      if (true) {
        message = $localize`Email sent`;
      } else {
        message = $localize`!! Email not sent`;
      }
      this._snackBar.open(message, $localize`Undo`, {
        duration: 5000,
        verticalPosition: "top",
      });
      //this.reloadComponent(this.id);
    });
  }

  convertOptionVal(input: string) {
    if (input == "1") return "User";
    if (input == "2") return "Ressource";
    return "Unknown";
  }

  getGroup() {
    let myGroup = "";
    if (this.id != "") {
      myGroup = this.id;
    } else {
      // myGroup = this.user$
    }
    return myGroup;
  }
}
