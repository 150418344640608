import { Component, OnInit, LOCALE_ID, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";

export interface ForecastData {
  time: string;
  forecastPrice: string;
  spotPrice: string;
  spotpriceStatus: string;
  forecastStatus: string;
}

@Component({
  selector: "app-forecast",
  templateUrl: "./forecast.component.html",
  styleUrls: ["./forecast.component.scss"],
})
export class ForecastComponent implements OnInit {
  constructor(
    private _router: Router,
    private cookieService: CookieService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}
  energyData$: Observable<ForecastData[]>;

  displayedColumns: string[] = [
    "time",
    "spotPrice",
    "forecastPrice",
    //"spotpriceStatus",
    //"forecastStatus",
  ];

  myData;
  forecastData = [];
  spotData = [];
  agregatedDataArray = [];
  month = "";

  languages = [
    { code: "en", label: "English" },
    { code: "da", label: "Danish" },
  ];

  ngOnInit(): void {
    // this.getPrognose();
    this.getSpotPrice();
  }

  getDay(dateString) {
    dateString = dateString + "Z";
    const date = new Date(dateString);
    const day = date.getDay();
    return day;
  }

  getDayText(dateString) {
    //Date string is UTC
    dateString = dateString + "Z";
    const date = new Date(dateString);
    let myDate = "";
    let splitChar = "";
    if (this.localeId == "en") {
      myDate = date.toLocaleString("en-GB", {
        weekday: "long",
        hour: "2-digit",
      });
      splitChar = ",";
    }
    if (this.localeId == "da") {
      myDate = date.toLocaleString("da-DK", {
        weekday: "long",
        hour: "numeric",
      });
      splitChar = " ";
    }
    //console.log(myDate);
    let aDay = myDate.split(splitChar)[0];
    let aHour = myDate.split(splitChar)[1];
    let nextHour = Number(aHour) + 1;

    if (nextHour == 24) {
      nextHour = 0;
    }

    let nextHourString = String(nextHour);

    if (nextHourString.length == 1) {
      nextHourString = "0" + nextHourString;
    }

    return aDay + " " + aHour + ".00" + " - " + nextHourString + ".00";
  }

  getHour(dateString) {
    //Date string is UTC
    dateString = dateString + "Z";
    const date = new Date(dateString);
    const day = date.getHours();
    return day;
  }

  getToDay() {
    const date = new Date();
    const day = date.getDay();
    return day;
  }

  mergeForecastAndSpotData(spotData, forecast) {
    console.log("merge");
    console.log(spotData);
    console.log(forecast);
    forecast.forEach((record, index) => {
      let agregatedData = {};
      agregatedData["time"] = record.Time;
      agregatedData["forecastPrice"] = record.Price;
      let obj = spotData.find((o) => o.HourUTC === record.Time);
      try {
        agregatedData["spotPrice"] = obj.SpotPriceDKK;
      } catch (e: any) {
        agregatedData["spotPrice"] = "NA";
        //console.log(e);
      } finally {
        //console.log("finally");
      }
      this.agregatedDataArray.push(agregatedData);
    });
    console.log(this.agregatedDataArray);
  }

  prepareAgregatedData(data) {
    // finding max Price
    let test = Math.max.apply(
      Math,
      data.map(function (o) {
        return o.Price;
      })
    );
    console.log("Start:");
    this.mergeForecastAndSpotData(this.spotData, data);

    let currentTime = new Date();
    currentTime.setUTCMinutes(0);
    currentTime.setUTCSeconds(0);
    currentTime.setUTCMilliseconds(0);

    let dataTime = new Date(this.agregatedDataArray[0].time + "Z");

    console.log(dataTime);

    this.agregatedDataArray.forEach((record, index) => {
      let hourData = {};
      //console.log(index);
      if (new Date(record.time + "Z") >= currentTime) {
        hourData["time"] = this.getDayText(record.time);
        let roundedForecastIndex = (record.forecastPrice / test).toFixed(2);
        hourData["forecastPrice"] = roundedForecastIndex;
        hourData["spotPrice"] = record.spotPrice;

        let forecastStatus = "low";
        let spotPriceStatus = "low";

        if (record.spotPrice !== "NA") {
          spotPriceStatus = "low";
          if (record.spotPrice * 1 > 0.4) {
            spotPriceStatus = "medium";
          }
          if (record.spotPrice * 1 > 0.8) {
            spotPriceStatus = "high";
          }
        } else {
          spotPriceStatus = "na";
        }

        if (parseFloat(roundedForecastIndex) > 0.4) {
          forecastStatus = "medium";
        }
        if (parseFloat(roundedForecastIndex) > 0.8) {
          forecastStatus = "high";
        }

        hourData["spotpriceStatus"] = spotPriceStatus;
        hourData["forecastStatus"] = forecastStatus;
        // console.log(record.time);

        this.forecastData.push(hourData);
      }
    });
    this.energyData$ = of(this.forecastData);
    console.log(this.forecastData);
  }
  getSpotPricesNext(data) {
    console.log("log");
    console.log(data);
    let yourDate = new Date();
    let todayString = yourDate.toISOString().split("T")[0];
    let tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    let tomorrowString = tommorow.toISOString().split("T")[0];
    let myHour = new Date().getHours();

    data.records.forEach((record) => {
      let hourData = {};

      if (record.PriceArea.includes("DK1")) {
        //console.log(record);
        if (
          record.HourDK.includes(todayString) ||
          record.HourDK.includes(tomorrowString)
        ) {
          if (this.getDay(record.HourDK) !== this.getToDay()) {
            hourData["HourUTC"] = record.HourUTC;
            hourData["SpotPriceDKK"] = (record.SpotPriceDKK / 1000).toFixed(2);
            this.spotData.push(hourData);
          } else {
            if (this.getHour(record.HourDK) >= myHour) {
              hourData["HourUTC"] = record.HourUTC;
              hourData["SpotPriceDKK"] = (record.SpotPriceDKK / 1000).toFixed(
                2
              );
              this.spotData.push(hourData);
            }
          }
        }
      }
    });
    console.log(this.spotData);
    this.spotData.reverse();
    this.getForecast();
  }

  getSpotPrice() {
    let spotPrices = [];
    let date = new Date();
    //console.log(this.localeId);
    let myDate = "";
    if (this.localeId == "en") {
      myDate = date.toLocaleString("en-GB", { month: "long" });
    }
    if (this.localeId == "da") {
      myDate = date.toLocaleString("da-DK", { month: "long" });
    }

    this.month = myDate.charAt(0).toUpperCase() + myDate.slice(1);
    //let firstDay = new Date(date.setDate(date.getDate() - 2));
    //let lastDay = new Date(date.setDate(date.getDate() + 2));
    //console.log(firstDay_);
    //console.log(lastDay_);

    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let firstDayString = firstDay.toISOString();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    let lastDayString = lastDay.toISOString();
    let firstDayString1 = firstDayString.substring(
      0,
      firstDayString.length - 13
    );
    let lastDayString1 = lastDayString.substring(0, lastDayString.length - 13);
    let firstDayString2 = firstDayString1 + "00:00";
    let lastDayString2 = lastDayString1 + "00:00";
    console.log("first: " + firstDayString2);
    console.log("Last: " + lastDayString2);
    let url =
      "https://api.energidataservice.dk/dataset/Elspotprices?offset=0&start={1}&end={2}&filter=%7B%22PriceArea%22:[%22DK1%22,%22DK2%22]%7D&sort=HourUTC%20DESC&timezone=dk";
    let url1 = url.replace("{1}", firstDayString2);
    let url2 = url1.replace("{2}", lastDayString2);
    //console.log(url1);
    console.log(url2);
    //Get specified amount of data from specified dataset
    fetch(url2)
      .then((response) => response.json())
      .then((data) => {
        this.getSpotPricesNext(data);
      });
  }

  getForecast() {
    let url =
      "https://raw.githubusercontent.com/solmoller/Spotprisprognose/main/prognose.json";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.myData = data.DK1;
        this.prepareAgregatedData(data.DK1);
        console.log(data.DK1);
      });
  }
}
