<mat-card>
<mat-card class="my-class-name asd cardPardding">
  <mat-card-title><span i18n>All Users</span></mat-card-title>
  <button mat-raised-button color="primary" (click)="newUserDialog()"><span i18n>New User</span></button>
</mat-card>

<mat-card>
  <div *ngIf="users$ | async as users; else indicator" class="list-group">
    {{user}} 
    <mat-list>
      <mat-list-item *ngFor="let user of users" >
        <p matLine>{{user.options}}</p>
        <p matLine>{{user.displayName}}</p>
        <p matLine>{{user.email}}</p>
        <p matLine>{{user.uid}}</p>
        <button mat-raised-button color="primary" (click)="openDeleteDialog(user)"><span i18n>Delete</span></button>
        
        <p matLine>
             <mat-chip-list>
          <mat-chip color="primary" selected>{{user.groups}}</mat-chip>
           </mat-chip-list>
        </p>
        <p matLine>
           <mat-chip-list>
          <mat-chip color="accent" selected>{{user.role}}</mat-chip>
        </mat-chip-list>
        </p>
        
          <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list> 
  </div>
</mat-card>
</mat-card>
<ng-template #indicator>
  <mat-card style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner diameter="300"
      color="primary" 
      mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
    

</ng-template>

  