import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { SettingsMy } from "../models/settingsMy";
import { SettingsGroup } from "../models/settingsGroup";
import { GroupConfig } from "../models/groupConfig";
import { UpdateConfig } from "../models/updateConfig";
import { Tokens } from "../models/tokens";
import { SettingsActiveGroup } from "../models/settingsActiveGroup";
import SettingsJson from "../../../assets/appsettings.json";
import { environment } from "../../../environments/environment";

export type NewUserSettingRequest = {
  Active: string;
  Options: string;
  CurrentUser: string;
  Email: string;
  Include: string;
  Name: string;
  SendEmail: string;
  ShortName: string;
  UserId: string;
  TrafficLocation: string;
  LicensePlate: string;
  CarModel: string;
  Uuid: string;
  UuidMajor: string;
};

export type NewUserTokenRequest = {
  urlBackend: string;
  urlFrontend: string;
  serverName: string;
  group: string;
  userid: string;
};

export type emailTokenRequest = {
  serverName: string;
  group: string;
  userid: string;
  email: string;
};

export type DeleteUserTokenRequest = {
  userid: string;
};

export interface TripUpdateRespone {
  passengerResult: string;
}

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  //url = "http://dummy.restapiexample.com/api/v1/employee/34";

  constructor(private http: HttpClient) {
    console.log("constructor: ");
    //console.log(SettingsJson);
  }

  get settingsmy$(): Observable<SettingsMy[]> {
    let baseUrl = environment.userApi;
    let userBaseUrl = baseUrl + SettingsJson.userUrl;
    console.log("settingsmy userBaseUrl: " + userBaseUrl);
    return this.http.get<{ settings: SettingsMy[] }>(`${userBaseUrl}`).pipe(
      tap((val) =>
        console.log(`settingsmy BEFORE MAP: ${JSON.stringify(val)}`)
      ),
      map((result) => {
        //console.log(result.settings)
        return result.settings;
      })
    );
  }

  get usertokens$(): Observable<Tokens[]> {
    // const params = new HttpParams().set("group", group);
    let baseUrl = environment.userApi;
    let userTokensBaseUrl = baseUrl + SettingsJson.tokensUrl;
    console.log("userTokensBaseUrl: " + userTokensBaseUrl);
    return this.http.get(`${userTokensBaseUrl}`).pipe(
      tap((val) =>
        console.log(`usertokens service BEFORE MAP: ${JSON.stringify(val)}`)
      ),
      map((val: Tokens[]) => {
        console.log(val);
        return val;
      })
    );
  }

  settingsgroup$(group: any): Observable<SettingsGroup[]> {
    const params = new HttpParams().set("group", group);
    let baseUrl = environment.userApi;
    let groupBaseUrl = baseUrl + SettingsJson.groupUrl;
    console.log("groupBaseUrl: " + groupBaseUrl);
    return this.http
      .get<{ settings: SettingsGroup[] }>(`${groupBaseUrl}`, { params })
      .pipe(
        tap((val) =>
          console.log(`settingsgroup BEFORE MAP: ${JSON.stringify(val)}`)
        ),
        map((result) => {
          console.log(result.settings);
          return result.settings;
        })
      );
  }

  groupConfig$(group: any): Observable<GroupConfig[]> {
    const params = new HttpParams().set("group", group);
    let baseUrl = environment.userApi;
    let groupConfigBaseUrl = baseUrl + SettingsJson.groupConfigUrl;
    console.log("groupConfigBaseUrl: " + groupConfigBaseUrl);
    return this.http
      .get<{ groupconfigs: GroupConfig[] }>(`${groupConfigBaseUrl}`, { params })
      .pipe(
        tap((val) =>
          console.log(`configgroup BEFORE MAP: ${JSON.stringify(val)}`)
        ),
        map((result) => {
          console.log(result.groupconfigs);
          return result.groupconfigs;
        })
      );
  }

  get settingsActiveGroups$(): Observable<SettingsActiveGroup[]> {
    let baseUrl = environment.userApi;
    let activeGroupsUrl = baseUrl + SettingsJson.activeGroupsUrl;
    console.log("activeGroupsUrl: " + activeGroupsUrl);
    return this.http
      .get<{ groups: SettingsActiveGroup[] }>(`${activeGroupsUrl}`)
      .pipe(
        tap((val) =>
          console.log(`settingsActiveGroups BEFORE MAP: ${JSON.stringify(val)}`)
        ),
        map((result) => {
          console.log(result);
          return result.groups;
        })
      );
  }

  get settingsscan$(): Observable<SettingsGroup[]> {
    let baseUrl = environment.userApi;
    let scanBaseUrl = baseUrl + SettingsJson.scanUrl;
    console.log("scanBaseUrl: " + scanBaseUrl);
    return this.http.get<{ settings: SettingsGroup[] }>(`${scanBaseUrl}`).pipe(
      tap((val) =>
        console.log(`settingsscan BEFORE MAP: ${JSON.stringify(val)}`)
      ),
      map((result) => {
        console.log(result.settings);
        return result.settings;
      })
    );
  }

  CreateNewUserSetting(user: NewUserSettingRequest, group: any) {
    const params = new HttpParams().set("group", group);
    console.log("Group: " + group);
    console.log("newUserSettingRequest: " + JSON.stringify(user));
    let baseUrl = environment.userApi;
    let newUserBaseUrl = baseUrl + SettingsJson.newUserUrl;
    console.log("newUserBaseUrl: " + newUserBaseUrl);
    return this.http
      .post(`${newUserBaseUrl}`, user, { params })
      .pipe(map((_) => {}));
  }

  async getSettingsGroupDataUsingAsync(group: any) {
    const params = new HttpParams().set("group", group);
    let baseUrl = environment.userApi;
    let groupBaseUrl = baseUrl + SettingsJson.groupUrl;
    //console.log("groupBaseUrl: " + groupBaseUrl);
    let data = await this.http
      .get<{ settings: SettingsGroup[] }>(`${groupBaseUrl}`, { params })
      .toPromise();

    console.log("************");
    console.log(data);
    let result = [];
    data.settings.forEach((element) => {
      let element_object = {
        ShortName: element.ShortName,
        CarId: element.CarId,
        UserId: element.UserId,
      };
      if (element.Options === "1") {
        result.push(element_object);
      }
    });
    return result;
  }

  CreateNewUserToken(user: NewUserTokenRequest) {
    console.log("newUserTokenRequest: " + JSON.stringify(user));
    let baseUrl = environment.userApi;
    let newUserTokenBaseUrl = baseUrl + SettingsJson.newUserTokenUrl;
    console.log("newUserBaseUrl: " + newUserTokenBaseUrl);

    return this.http.post(`${newUserTokenBaseUrl}`, user).pipe(map((_) => {}));
  }

  createTokenEmail(user: emailTokenRequest) {
    console.log("newUTokenEmailRequest: " + JSON.stringify(user));
    let baseUrl = environment.userApi;
    let emailTokenBaseUrl = baseUrl + SettingsJson.emailTokenUrl;
    console.log("emailTokenBaseUrl: " + emailTokenBaseUrl);

    return this.http.post(`${emailTokenBaseUrl}`, user).pipe(
      map((result) => {
        return result;
      })
    );
  }

  CreateTrip(config: UpdateConfig, group: any) {
    const params = new HttpParams().set("group", group);
    console.log("Group: " + group);
    console.log("updateConfig: " + JSON.stringify(config));
    let baseUrl = environment.userApi;
    let missingUpdatesBaseUrl = baseUrl + SettingsJson.missingupdatesUrl;
    console.log("missingUpdatesBaseUrl: " + missingUpdatesBaseUrl);

    return this.http
      .patch<TripUpdateRespone>(`${missingUpdatesBaseUrl}`, config, { params })
      .pipe(
        tap((val) =>
          console.log(`CreateTrip BEFORE MAP: ${JSON.stringify(val)}`)
        ),
        map((result) => {
          console.log(result.passengerResult);
          return result;
        })
      );
  }

  CreateConfig(config: GroupConfig, group: any) {
    const params = new HttpParams().set("group", group);
    console.log("Group: " + group);
    console.log("newConfig: " + JSON.stringify(config));
    let baseUrl = environment.userApi;
    let groupConfigBaseUrl = baseUrl + SettingsJson.groupConfigUrl;
    console.log("groupConfigBaseUrl: " + groupConfigBaseUrl);

    return this.http
      .post(`${groupConfigBaseUrl}`, config, { params })
      .pipe(map((_) => {}));
  }

  DeleteUserToken(user: DeleteUserTokenRequest) {
    //const params = new HttpParams().set("group", group);
    //console.log("Group: " + group);

    console.log("deleteUserToken: " + JSON.stringify(user));
    let baseUrl = environment.userApi;
    let deleteUserTokenBaseUrl = baseUrl + SettingsJson.deleteUserTokenUrl;
    console.log("deleteUserTokenBaseUrl: " + deleteUserTokenBaseUrl);

    return (
      this.http
        .post(`${deleteUserTokenBaseUrl}`, user)
        // .post(`${newUserTokenBaseUrl}`, user, { params })
        .pipe(map((_) => {}))
    );
  }

  DeleteUserSetting(user: string, group: any) {
    let myUser = {
      UserId: user,
    };
    const params = new HttpParams().set("group", group);
    console.log("Group: " + group);
    console.log("Id: " + user);
    let baseUrl = environment.userApi;
    let deleteUserBaseUrl = baseUrl + SettingsJson.deleteUserUrl;
    console.log("deleteUserBaseUrl: " + deleteUserBaseUrl);

    return this.http
      .post(`${deleteUserBaseUrl}`, myUser, { params })
      .pipe(map((_) => {}));
  }

  editUserSetting(
    user: string,
    group: any,
    shortname: any,
    uuidmajor: any,
    trafficlocation: any,
    licenseplate: any,
    carmodel: any,
    batterychangedate: any
  ) {
    trafficlocation = ""; // ToDO field has to be removed
    let myUser = {
      UserId: user,
      ShortName: shortname,
      UuidMajor: uuidmajor,
      TrafficLocation: trafficlocation,
      LicensePlate: licenseplate,
      CarModel: carmodel,
      BatteryChangeDate: batterychangedate,
    };
    const params = new HttpParams().set("group", group);
    console.log("Group: " + group);
    console.log("Id: " + user);
    console.log("License: " + licenseplate);
    console.log("ShortName: " + shortname);
    console.log("Uuidmajor: " + uuidmajor);
    let baseUrl = environment.userApi;

    let userBaseUrl = baseUrl + SettingsJson.userUrl;
    console.log("userBaseUrl: " + userBaseUrl);
    console.log("user Data: " + JSON.stringify(myUser));

    return this.http
      .patch(`${userBaseUrl}`, myUser, { params })
      .pipe(map((_) => {}));
  }
}
