import {
  Component,
  Directive,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
import { ActivatedRoute } from "@angular/router";
import { SettingsService } from "../settings/services/settings.service";
import { map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { GroupConfig } from "../settings/models/groupConfig";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

export interface SettingsFlags {
  id: number;
  name: string;
}

@Component({
  selector: "app-server-settings",
  templateUrl: "./server-settings.component.html",
  styleUrls: ["./server-settings.component.scss"],
})
export class ServerSettingsComponent implements OnInit, AfterViewInit {
  @ViewChild("slider", { static: false }) slider2: ElementRef;
  @ViewChild("circle1", { static: false }) circle1: ElementRef;
  @ViewChild("circle2", { static: false }) circle2: ElementRef;
  @ViewChild("circle3", { static: false }) circle3: ElementRef;
  @ViewChild("circle4", { static: false }) circle4: ElementRef;
  @ViewChild("circle5", { static: false }) circle5: ElementRef;

  submitStatus: boolean = false;
  settingsForm: FormGroup;
  id: string;

  notificationUrl: string;
  tripThresholdCount: number;
  tripTimeOutValueInMinuttes: number;

  groupConfig$: Observable<GroupConfig[]>;

  settingsFlagData: SettingsFlags[] = [
    { id: 0, name: "Enable Notification" },
    //{ id: 1, name: 'Notification 2' },
    //{ id: 2, name: 'Notification 3' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.settingsForm = this.formBuilder.group({
      utcOffset: [, { validators: [Validators.required], updateOn: "change" }],
      input_timeout: [20, Validators.required],
      input_duration: [20, Validators.required],
      timeout_slider: [20, Validators.required],
      duration_slider: [20, Validators.required],
      form_flags: this.formBuilder.array([]),
      serverName: [, { validators: [Validators.required], updateOn: "change" }],
      apiKey: [, { validators: [Validators.required], updateOn: "change" }],
      serverUrl: [, { validators: [Validators.required], updateOn: "change" }],
    });

    this.settingsForm
      .get("timeout_slider")
      .valueChanges.subscribe((sliderVal) => {
        console.log(sliderVal);
        let newVal = this.calculateTimeoutValue(sliderVal);
        this.settingsForm.get("input_timeout").setValue(newVal);
      });
    this.settingsForm
      .get("duration_slider")
      .valueChanges.subscribe((sliderVal) => {
        console.log(sliderVal);
        let newVal = this.calculateDurationValue(sliderVal);
        this.settingsForm.get("input_duration").setValue(newVal);
      });
  }

  calculateTimeoutValue(sliderVal: any) {
    let newVal: number = Math.round(3 + 7 * (sliderVal / 100));
    return newVal;
  }

  calculateDurationValue(sliderVal: any) {
    let newVal: number = Math.round(3 + 7 * (sliderVal / 100));
    return newVal;
  }
  /*
  onChangeEventFunc(name: string, isChecked: boolean) {
    const settingsFlags = (this.settingsForm.controls.form_flags as FormArray);

    if (isChecked) {
      settingsFlags.push(new FormControl(name));
    } else {
      const index = settingsFlags.controls.findIndex(x => x.value === name);
      settingsFlags.removeAt(index);
    }
  }
*/

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    let appType = environment.app_type;
    this.getGroupConfig(this.id, appType);
    console.log(this.id);
  }

  submitForm() {
    console.log(this.settingsForm.valid);
    console.log(this.settingsForm.controls.serverUrl.value);
    this.submitStatus = true;

    let configData = {
      notificationUrl: this.settingsForm.controls.serverUrl.value,
      tripThresholdCount: this.settingsForm.controls.input_duration.value,
      tripTimeOutValueInMinuttes:
        this.settingsForm.controls.input_timeout.value,
      apiKey: this.settingsForm.controls.apiKey.value,
      notificationEnabled: false,
      utcOffset: this.settingsForm.controls.utcOffset.value,
    };

    console.log(configData);
    this.settingsService.CreateConfig(configData, this.id).subscribe((_) => {
      let message = "Config saved";
      console.log(message);
      this._snackBar.open(message, $localize`Undo`, {
        duration: 5000,
        verticalPosition: "top",
      });
      this.reloadComponent(this.id);
    });
  }

  public getGroupConfig(group: string, appType: string) {
    console.log("getGroupConfig !!");

    // this.groupConfig$.subscribe(event => this.event = event);

    this.settingsService
      .groupConfig$(group)
      .pipe(
        map((val) => {
          this.groupConfig$ = of(val);
          this.settingsForm
            .get("input_timeout")
            .setValue(val[0].tripTimeOutValueInMinuttes);
          this.settingsForm
            .get("input_duration")
            .setValue(val[0].tripThresholdCount);
          this.settingsForm.get("serverUrl").setValue(val[0].notificationUrl);
          this.settingsForm.get("apiKey").setValue(val[0].apiKey);
          this.settingsForm.get("utcOffset").setValue(val[0].utcOffset);
        })
      )
      .subscribe((val) => console.log("at Subscriber " + JSON.stringify(val)));
  }

  reloadComponent(id: string) {
    console.log("reloadComponent:");
    console.log(id);
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/serversettings", id]);
  }

  ngAfterViewInit() {}

  getGroup() {
    let myGroup = "";
    if (this.id != "") {
      myGroup = this.id;
    } else {
      // myGroup = this.user$
    }
    return myGroup;
  }
}
