import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy/privacy.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';

const routes: Routes = [{
  path: 'about', component: PrivacyComponent
}];

@NgModule({
  declarations: [
    PrivacyComponent
  ],
  imports: [
    RouterModule.forChild(routes), 
    BrowserModule,
    MatCardModule,
    MatGridListModule
  ],
  exports: [RouterModule] 
})
export class PrivacyModule {

  constructor(

  ) {

  }

}
