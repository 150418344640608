<mat-card>
  <table mat-table id="excel-table" [dataSource]="tripsByCount$">
    <ng-container matColumnDef="driver">
        <th mat-header-cell *matHeaderCellDef><mat-icon class="icon commute" >commute</mat-icon></th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.ownerpresent ; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock> 
            <div>
              <span [ngStyle]="{'color':'green','font-weight':'bold'}">{{element.driver}}</span>
            </div>
          </ng-template>
          <ng-template #elseBlock>
            <div>
              <span [ngStyle]="{'color':'red','font-weight':'bold'}">{{element.driver}}</span>
            </div>
          </ng-template>
        </td>
    </ng-container>
    <ng-container matColumnDef="passenger1">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>1</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger1}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger2">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>2</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger2}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger3">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>3</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger3}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger4">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>4</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger4}} </td>
    </ng-container>
    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon count" >add_task</mat-icon></th>
      <td mat-cell *matCellDef="let element"> {{element.count}} </td> 
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon schedule" >schedule</mat-icon></th>
      <td mat-cell *matCellDef="let element"> {{element.date}} </td> 
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let entry" [ngClass]="{ 'highlight': entry.nextToDrive == 1 }"></tr>
  </table>
</mat-card>

<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner diameter="300"
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>
