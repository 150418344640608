import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { ForecastComponent } from "./forecast/forecast.component";
import { BrowserModule } from "@angular/platform-browser";
import { MatCardModule } from "@angular/material/card";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDividerModule } from "@angular/material/divider";
import { MatTableModule } from "@angular/material/table";
import { MatChipsModule } from "@angular/material/chips";

const routes: Routes = [
  {
    path: "forecast",
    component: ForecastComponent,
  },
];

@NgModule({
  declarations: [ForecastComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatGridListModule,
    MatDividerModule,
    MatTableModule,
    MatChipsModule,
  ],
  exports: [ForecastComponent],
})
export class ForecastModule {
  constructor() {}
}
