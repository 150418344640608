<div *ngIf="appMode$ | async; let app_mode" class="list-group">  
  <div id="content">
    <div *ngIf="app_mode == 'demo'; else elseBlock">
        <mat-grid-list cols="5" rowHeight="1:2.2">
          <mat-grid-tile colspan="2">
            <img class="image" mat-card-image src="assets/pass_reset.svg" alt="photo">   
          </mat-grid-tile>    
          
          <mat-grid-tile>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <div>
              <h1><span i18n>Disabled in demo mode</span></h1>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
    </div>
    <ng-template #elseBlock>
      <mat-card>
        <mat-card-title><span i18n>Reset Password</span></mat-card-title>
        <div>
        <mat-grid-list cols="5" rowHeight="1:2.2">
          <mat-grid-tile colspan="2">
            <img class="image" mat-card-image src="assets/pass_reset.svg" alt="photo">   
          </mat-grid-tile>    
          
          <mat-grid-tile>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
           
          </mat-grid-tile>
      </mat-grid-list>
      </div>
      <div>
    
        <mat-grid-list cols="5" rowHeight="1:2.1"> 
          <mat-grid-tile colspan="5">
            <span i18n>Enter email and push "Reset password" button in order to change password</span>
            <!--
              Indtast email addresse og tryk på "Nulstil Password" for at ændre password.
            Hvis email addressen er registreret i systemet, vil der blive sendt en email til addressen med yderligere instruktioner.
            -->
          </mat-grid-tile>
          

          <mat-grid-tile colspan="5">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit(resetPasswordForm.value)">
                <div align="right"> 
                <label for="email">
                  <span i18n>Email</span><input id="email" placeholder="test@example.com" type="text" formControlName="email" />
                </label>
                <br>
                <button class="mat-raised-button mat-primary" type="submit"><span i18n>Reset password</span></button>
              </div> 
            </form>
          </mat-grid-tile>
      </mat-grid-list>
      </div>
      </mat-card>
    </ng-template>
  </div>
</div>
