import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogData } from "./DialogData";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";
import { Observable, of } from "rxjs";
import SettingsJson from "../../../assets/appsettings.json";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-edit-settings",
  templateUrl: "./edit-settings.component.html",
  styleUrls: ["./edit-settings.component.scss"],
})
export class EditSettingsComponent implements OnInit {
  form: FormGroup;
  //newuserform: FormGroup;

  userid: string;
  groupid: string;
  post: any = "";
  displayname: string;
  shortname: string;
  licenseplate: string;
  carmodel: string;
  batterychangedate: string;
  uuidmajor: string;
  trafficlocation: string;

  usertype: string;
  shortNameAlert: string = "Short name is required: ";
  sensorCodeAlert: string = "Sensor code is required: (1 - 65000)";
  appType$: Observable<string>;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<EditSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userid = data.userid;
    this.groupid = data.groupid;

    this.carmodel = data.carmodel;
    this.batterychangedate = data.batterychangedate;
    this.licenseplate = data.licenseplate;

    this.displayname = data.displayname;
    this.shortname = data.shortname;
    this.uuidmajor = data.uuidmajor;
    this.trafficlocation = data.trafficlocation;
    this.usertype = data.usertype;
  }

  ngOnInit() {
    let appType = environment.app_type;
    this.appType$ = of(appType);
    let locationRegex: RegExp =
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
    this.form = this.fb.group({
      UserId: [this.userid],
      GroupId: [this.groupid],
      Name: new FormControl({ value: this.displayname, disabled: true }),
      ShortName: [this.shortname, [Validators.required]],
      //TrafficLocation: [this.trafficlocation, [Validators.required, Validators.pattern(locationRegex)]],
      Token: [{ value: this.groupid, disabled: true }],
      UuidMajor: new FormControl({ value: this.uuidmajor, disabled: true }),
      /*
      UuidMajor: [
        this.uuidmajor,
        [Validators.required, Validators.min(1), Validators.max(65000)],
      ],
*/
      LicensePlate: [this.licenseplate, [Validators.required]],
      CarModel: [this.carmodel, [Validators.required]],
      BatteryChangeDate: [this.batterychangedate],
    });

    this.dialogRef.updatePosition({ top: `30px` });
  }

  save() {
    console.log("save !!");
    console.log(this.form.value);
    this.dialogRef.close(this.form.value);
  }

  save2() {
    console.log("save2 !!");
    //console.log(this.form.value);
    //this.dialogRef.close(this.form.value);
  }

  onSubmit(post) {
    this.post = post;
    console.log("edit-settings onSubmit:" + JSON.stringify(post));
    //Get all data from form, even disabled data
    let myData = this.form.getRawValue();
    //Remove Name and Token since they are not used in interface
    delete myData.Name;
    delete myData.Token;
    this.dialogRef.close(myData);
  }

  onNoClick(): void {
    console.log("cancel !!");
    this.dialogRef.close();
  }
}
