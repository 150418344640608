<mat-card id="card" style="text-align: center;">
    <h1><span i18n>Clever budget:</span> {{month}}</h1>

    <mat-card class="my-class-name asd cardPardding" id="card" style="text-align: left;">
        <mat-card-header>
            <mat-card-title><span i18n>Tarif</span></mat-card-title>
        </mat-card-header>
        <p matLine><span i18n>System tarif:</span> {{systemTarif}} <span i18n>Kr/KWh</span></p>
        <p matLine><span i18n>Net tarif (average):</span> {{averageTarifNight}} <span i18n>Kr/KWh</span></p>
    </mat-card>

    <mat-card class="my-class-name asd cardPardding" id="card" style="text-align: left;">
        <mat-card-header>
            <mat-card-title><span i18n>Average</span></mat-card-title>
            </mat-card-header>
        <p matLine><span i18n>Average day (24h):</span> {{averageKwhPriceIncludingVat}} <span i18n>Kr/KWh</span></p>
        <p matLine><span i18n>Average Night (23-06):</span> {{averageKwhPriceIncludingVatNight}} <span i18n></span>Kr/KWh</p>
    </mat-card>
    
    <mat-card class="my-class-name asd cardPardding" id="card" style="text-align: left;">
        <mat-card-header>
            <mat-card-title><span i18n>Clever estimate:</span></mat-card-title>
        </mat-card-header>
    <p matLine><span i18n>Refund price:</span> {{refusionPrice}} <span i18n>Kr/KWh</span></p>
    <p matLine><span i18n>Energy addendum:</span> {{energyAddendum}} <span i18n>Kr/KWh</span></p>
</mat-card>
<!--
<mat-card id="card" style="text-align: center;">
    <mat-card-header>
        <mat-card-title><span i18n>Spotprice:</span></mat-card-title>
    </mat-card-header>
<div class="container">
    <table class="table table-striped table-bordered">
        <thead>
            <tr>
                <th><span i18n>Time</span></th>
                <th><span i18n>Price</span></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let record of spotData">
                <td>{{record.HourDK | date: 'short'}}</td>
                <td><mat-chip>{{record.SpotPriceDKK}} <span i18n>Kr/KWh</span></mat-chip></td>
            </tr>
        </tbody>
    </table>
</div>
</mat-card>
-->
</mat-card>
