  <mat-card id="card" style="text-align: center;">
    <mat-card-title><span i18n>Settings</span></mat-card-title>
    <div>
    <mat-grid-list cols="3" rowHeight="1:1.5">
      <mat-grid-tile>
        <img class="image" mat-card-image src="assets/faq_carpool.svg" alt="photo">   
      </mat-grid-tile>    
      <mat-grid-tile colsspan="2">
       
      </mat-grid-tile>
     
  </mat-grid-list>
    
  <h2><span i18n>Permissions</span></h2>  
  <span i18n>IOS needs permission.</span> 
  <!--
  IOS skal have tilladelse fra brugeren til at spore lokalitet i baggrunden.
  Lokaliteten bliver kun sporet når bilen kører, og lokalitets-dataene bliver kun brugt til at vise dialogen i IOS.
  Systemet benytter ikke lokalitets-data, kun tidspunkter når der bliver kørt (ikke positionen)
  Lokalitets dataene forlader ikke mobilen.
  -->
  <h2><span i18n>Dialog</span></h2>  
    
  <mat-grid-list cols="5" rowHeight="1:2">
    <mat-grid-tile colspan="5" >
        <span i18n>IOS shows permission dialog.</span> 
        <!--
        IOS vil i starten vise en dialog, hvor der skal gives tilladelse til at IOS må spore lokalitet i baggrunden.
        Tryk på "Tillad altid for at tillade systemet altid kan køre i baggrunden. 
        Hvis der ikke gives tilladelse vil systemet ikke kunne fungere."
        -->

    </mat-grid-tile> 
  </mat-grid-list>
  <mat-grid-list cols="5" rowHeight="1:11">
  
 
    <mat-grid-tile colspan="5">
        <span *ngIf="localeId === 'da'">
          <img class="image" mat-card-image src="assets/cartrack_permission_dialog_danish.jpg" alt="photo"> 
        </span>
        <span *ngIf="localeId === 'en'">
          <img class="image" mat-card-image src="assets/cartrack_permission_dialog_danish.jpg" alt="photo"> 
        </span>
    </mat-grid-tile>   
  </mat-grid-list>
  <h2><span i18n>Settings</span></h2> 
    <span i18n>
      Permission can be set in.
      </span>
      <!--
          Tilladelsen kan også gives i: <br> indstillinger -> cartrack3/carpool3 -> Lokalitet <br>
  For at systemet kan fungere, skal Lokalitet være sat til "Altid".
      -->


  <mat-grid-list cols="5" rowHeight="1:12">
    <mat-grid-tile colspan="5">
      <span *ngIf="localeId === 'da'">
        <img class="image" mat-card-image src="assets/settings-danish.png" alt="photo">  
      </span> 
      <span *ngIf="localeId === 'en'">
        <img class="image" mat-card-image src="assets/settings-english.png" alt="photo">  
      </span> 
    </mat-grid-tile>   
  </mat-grid-list>
  <h2><span i18n>Server</span></h2>  
    
  <mat-grid-list cols="5" rowHeight="1:1">
    <mat-grid-tile colspan="5" >
      <span i18n>
        In the field named Token, user has to put in the name of the tokens being used.
      </span>
      <!--
          I feltet server name skal man angive navnet på den server man vil benytte.
      -->

    </mat-grid-tile> 
  </mat-grid-list>
  
  </div>
  </mat-card>
