import { UserService } from "./../services/user.service";
import { SettingsService } from "./../../settings/services/settings.service";

import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../models/user";
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { UserFormComponent } from '../user-form/user-form.component';
import { NewUserComponent } from "../../new-user/new-user/new-user.component";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { switchMap, filter, finalize, subscribeOn } from "rxjs/operators";
import { map } from "rxjs/operators";
import { UserFormService } from "../services/user-form.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "./../../confirmation-dialog/confirmation-dialog/confirmation-dialog.component";
import { CookieService } from "ngx-cookie-service";
import { EditSettingsComponent } from "../../edit-settings/edit-settings/edit-settings.component";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  users$: Observable<User[]>;
  user$: Observable<User>;
  userid: string;
  userEmails$: Observable<string[]>;
  userEmails: string[];
  isLoading = false;

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private userForm: UserFormService,
    //private modal: NgbModal,
    private afAuth: AngularFireAuth,
    private _router: Router,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.cookieService.set("last-page-visited", "users");
    this.isLoading = true;
    this.users$ = this.userService.users$;
    //this.userEmails$ =  this.userService.userEmails$;
    this.user$ = this.afAuth.user.pipe(
      filter((user) => !!user),
      switchMap((user) => this.userService.user$(user.uid))
      //finalize(() => this.isLoading = false)
    );

    //this.test();
  }
  newUserDialog(): void {
    console.log("newUserDialog");

    const dialogRef = this.dialog.open(NewUserComponent, {
      minWidth: "20vw",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Saved3 result: " + JSON.stringify(result));
      if (typeof result !== "undefined") {
        let groupArr = [];
        groupArr.push(result.groupname);
        let googleUser = {
          displayName: result.fullname,
          email: result.email,
          role: "user",
          groups: groupArr,
          password: result.password,
        };

        console.log("GoogleUser: " + JSON.stringify(googleUser));
        this.userService.create(googleUser).subscribe((res) => {
          console.log("user created: " + JSON.stringify(result));

          let myUser = {
            Active: "1",
            Options: result.options,
            CurrentUser: "NA",
            Email: googleUser.email,
            Include: "0",
            Name: googleUser.displayName,
            SendEmail: "0",
            ShortName: "ZZ",
            UserId: res,
            TrafficLocation: "-34.397, 150.644",
            LicensePlate: "AA00000",
            CarModel: "TBD",
            Uuid: "1c4e0347-a8df-41a2-86ac-74ac19713d02",
            UuidMajor: result.UuidMajor,
          };

          this.settingsService
            .CreateNewUserSetting(myUser, googleUser.groups[0])
            .subscribe((_) => {
              //Create new token
              let myData = {
                urlBackend: environment.userApi,
                urlFrontend: environment.frontEndApi,
                serverName: environment.serverName,
                group: result.groupname,
                userid: res,
              };
              this.settingsService.CreateNewUserToken(myData).subscribe((_) => {
                console.log("New Token created");
                //this.reloadComponent(this.id);
              });
              console.log("user created");
              this.reloadComponent();
            });
        });
      }
    });
  }
  openEditDialog(userToEdit): void {
    console.log("openEditDialog");
    console.log(userToEdit);
    const dialogRef = this.dialog.open(EditSettingsComponent, {
      minWidth: "20vw",
      data: {
        userid: userToEdit.UserId,
        displayname: userToEdit.displayName,
        email: userToEdit.email,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("Saved result: " + JSON.stringify(result));
      if (typeof result !== "undefined") {
        this.settingsService
          .editUserSetting(
            result.UserId,
            result.GroupId,
            result.ShortName,
            result.UuidMajor,
            result.TrafficLocation,
            result.LicensePlate,
            result.CarModel,
            result.BatteryChangeDate
          )
          .subscribe((_) => {
            console.log("setting edited");
            this.reloadComponent();
          });
      }
    });
  }

  delete(userToDelete) {
    this.userService.delete(userToDelete).subscribe((_) => {
      console.log("user deleted: " + userToDelete.groups[0]);
      this.settingsService
        .DeleteUserSetting(userToDelete.uid, userToDelete.groups[0])
        .subscribe((_) => {
          //Add delete of token here:
          console.log("user settings deleted");
          this.reloadComponent();
        });
    });
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/users"]);
  }

  openDeleteDialog(userToDelete): void {
    console.log("delete: " + JSON.stringify(userToDelete));

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      //data: "Do you confirm the deletion of this data?",
      data: {
        payload: userToDelete,
        message: "Do you confirm the deletion of this user ?",
        name: userToDelete.displayName,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("clicked: " + JSON.stringify(result));
        this.delete(result);
        // DO SOMETHING
      }
    });
  }
}
