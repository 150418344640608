import { Component, OnInit } from "@angular/core";
import { PowerService } from "../services/power.service";
import { UserService } from "./../../users/services/user.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, of } from "rxjs";
import { PowerData, PowerUsage } from "../models/power";
import { map, tap } from "rxjs/operators";
import { switchMap, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../../auth/services/auth.service";
import * as XLSX from "xlsx";
import { User } from "./../../users/models/user";
import { LOCALE_ID, Inject } from "@angular/core";

@Component({
  selector: "app-power",
  templateUrl: "./power.component.html",
  styleUrls: ["./power.component.scss"],
})
export class PowerComponent implements OnInit {
  powerData$: Observable<PowerUsage[]>;
  user$: Observable<User>;
  tCost = "";
  tPower = "";
  average = "";
  month = "";
  newdata: any;
  displayedColumns: string[] = [
    "time",
    "amount",
    "spotPrice",
    "netTarif",
    "elAfgift",
    "balanceTarif",
    "systemTarif",
    "transTarif",
    "extraTotal",
    "expense",
    "totalCost",
  ];
  fileName = "TeslaPower";
  fileExtension = ".xlsx";

  languages = [
    { code: "en", label: "English" },
    { code: "da", label: "Danish" },
  ];

  constructor(
    private _powerService: PowerService,
    private _router: Router,
    private userService: UserService,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  ngOnInit(): void {
    this.getPower();
  }

  public getPower() {
    let DataArray = [];
    let monthlyCost: Number = 0;
    let monthlyPower: Number = 0;
    let totalCost: Number = 0;
    let totalPower: Number = 0;

    this.authService.myfirstgroup$
      .pipe(switchMap((group) => this._powerService.getdata(group)))
      .subscribe((res) => {
        // console.log(res);
        res.powerUsage.forEach((record, index) => {
          let newData = {};
          newData["NetTarif"] = 0.432;
          newData["Elafgift"] = 0.008;
          newData["BalanceTarif"] = 0.002;
          newData["SystemTarif"] = 0.054;
          newData["TransTarif"] = 0.058;
          newData["ExtraTotal"] =
            Number(newData["NetTarif"]) +
            Number(newData["Elafgift"]) +
            Number(newData["BalanceTarif"]) +
            Number(newData["SystemTarif"]) +
            Number(newData["TransTarif"]);

          //console.log(newData["Expense"]);
          newData["Time"] = record.Time + "Z";
          newData["Power"] = record.Power;
          newData["SpotPriceDK1"] = record.SpotPriceDK1;
          newData["SpotPriceDK2"] = record.SpotPriceDK2;
          newData["Expense"] =
            Number(newData["ExtraTotal"]) + Number(newData["SpotPriceDK1"]);
          newData["TotalCost"] =
            Number(newData["Power"]) * Number(newData["Expense"]);
          newData["TotalCost"] = newData["TotalCost"].toFixed(2);
          totalCost = newData["TotalCost"];
          totalPower = newData["Power"];

          monthlyCost = +monthlyCost + +totalCost;
          monthlyPower = +monthlyPower + +totalPower;
          DataArray.unshift(newData);
          //console.log(monthlyCost);

          let date = new Date();
          let myDate = "";
          if (this.localeId == "en") {
            myDate = date.toLocaleString("en-GB", { month: "long" });
          }
          if (this.localeId == "da") {
            myDate = date.toLocaleString("da-DK", { month: "long" });
          }

          this.month = myDate.charAt(0).toUpperCase() + myDate.slice(1);
        });

        this.powerData$ = of(DataArray);
        this.tCost = String(monthlyCost.toFixed(2));
        this.tPower = String(monthlyPower.toFixed(2));
        let aveNum = +monthlyCost / +monthlyPower;
        this.average = String(aveNum.toFixed(2));
      });
    //this.isLoading = false;
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/power"]);
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const myDateString = today.toUTCString();

    let fileInfo = "";

    fileInfo = this.fileName + "_" + myDateString + this.fileExtension;

    /* save to file */
    XLSX.writeFile(wb, fileInfo);
  }
}
