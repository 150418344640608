<div *ngIf="appType$ | async; let app_type">  
  <mat-card id="card" style="text-align: center;">
    <mat-card-title><span i18n>Privacy Policy</span></mat-card-title>
    <mat-grid-list cols="3" rowHeight="1:1.4">
      <mat-grid-tile>
        <img class="image" mat-card-image src="assets/privacy_protection_nlwy.svg" alt="photo">   
      </mat-grid-tile>    
      
    </mat-grid-list>

    <html>
    <head>
      <meta charset='utf-8'>
      <meta name='viewport' content='width=device-width'>
      <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; } </style>
    </head>
    <body i18n>powertrack privacy</body>
    </html>
  </mat-card>
  
</div>    
