import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupportComponent } from './support/support.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';

const routes: Routes = [{
  path: 'support', component: SupportComponent
}];

@NgModule({
  declarations: [
    SupportComponent
  ],
  imports: [RouterModule.forChild(routes), 
    BrowserModule,MatCardModule,MatFormFieldModule,
    FormsModule,
    MatGridListModule,
    ReactiveFormsModule],
  exports: [RouterModule] 
})
export class SupportModule {

  constructor(

  ) {

  }

}
