import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogData } from "./DialogData";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";
import { Observable, of } from "rxjs";
import SettingsJson from "../../../assets/appsettings.json";
import { environment } from "../../../environments/environment";
import { UpdateConfig } from "../../settings/models/updateConfig";
//import moment from "moment";

@Component({
  selector: "app-add-trip",
  templateUrl: "./add-trip.component.html",
  styleUrls: ["./add-trip.component.scss"],
})

//https://coryrylan.com/blog/creating-a-dynamic-checkbox-list-in-angular
export class AddTripComponent {
  form: FormGroup;
  passengersData = [];
  passengerNames = [];
  currentCarId = "";
  ownerid = "";
  group = "";
  placeholder = $localize`Datepicker-text`;
  maxDate = new Date();

  get passengersFormArray() {
    return this.form.controls.passengers as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddTripComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      CarId: new FormControl({ value: data.carid, disabled: false }),
      Name: new FormControl({ value: data.displayname, disabled: true }),
      passengers: new FormArray([], minSelectedCheckboxes(1)),
      startDate: ["", Validators.required],
      StartTime: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
        ],
      ],
      StopTime: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
        ],
      ],
    });
    this.currentCarId = data.carid;
    console.log(this.currentCarId);
    console.log("add trip constructor");
    console.log(data);
    let appType = environment.app_type;
    console.log(appType);
    if (appType === "carpool") {
      let passNames = data.passengernames;
      console.log(passNames);
      let ArrayResult: Array<string> = [];
      for (const element of passNames) {
        if (data.userid !== element.UserId) {
          ArrayResult.push(element);
        }
      }
      this.passengerNames = ArrayResult;
    } else {
      this.passengerNames = data.passengernames;
    }

    this.ownerid = data.userid;
    this.group = data.groupid;
    // async passengers
    /*
    of(this.getOrders()).subscribe((passengers) => {
      this.ordersData = passengers;
      this.addCheckboxes();
    });
*/
    // synchronous passengers
    this.passengersData = this.getPassengers();
    this.addCheckboxes();
  }

  private addCheckboxes() {
    this.passengersData.forEach(() =>
      this.passengersFormArray.push(new FormControl(false))
    );
  }

  getPassengers() {
    console.log("getPassengers()");
    console.log(this.passengerNames);
    return this.passengerNames;
  }

  addHours(date, hours) {
    date.setHours(date.getHours() + hours);

    return date;
  }

  submitTrip() {
    const selectedPassengerIds = this.form.value.passengers
      .map((checked, i) => (checked ? this.passengersData[i].CarId : null))
      .filter((v) => v !== null);

    let updateData: UpdateConfig = {
      carnumber: "",
      event_date: "",
      first_eventtime: "",
      last_eventtime: "",
      passengeridsArray: [""],
      ownerid: "",
    };

    console.log(this.form.value);
    const date = new Date(this.form.value.startDate);
    //Workaround for UTC time-difference
    const newDate = this.addHours(date, 3);
    const startDate = newDate.toISOString().split("T")[0];
    //let date = moment(this.form.value.startDate).format("YYYY-MM-DD");
    let starttime = this.form.value.StartTime;
    let stoptime = this.form.value.StopTime;
    let car_id = this.form.value.CarId;

    updateData.carnumber = car_id;
    updateData.event_date = startDate;
    updateData.first_eventtime = starttime + ":00";
    updateData.last_eventtime = stoptime + ":00";

    let myArrayResult: Array<string> = [];
    for (const element of selectedPassengerIds) {
      let myresult = getBackendIdFromArray(this.passengerNames, element);
      if (myresult !== "") {
        myArrayResult.push(myresult);
      }
    }
    updateData.ownerid = this.ownerid;
    updateData.passengeridsArray = myArrayResult;
    console.log("hej");
    console.log(updateData);
    this.dialogRef.close(updateData);
  }
}

function getBackendIdFromArray(theArray: any, carId: any) {
  let result: string = "";
  for (const element of theArray) {
    if (element.CarId == carId) {
      result = element.UserId;
    }
  }
  return result;
}

function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map((control) => control.value)
      .reduce((prev, next) => (next ? prev + next : prev), 0);

    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}
