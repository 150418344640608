<mat-card>
    <ul *ngIf="groupConfig$ | async; let config" class="list-group">
       <!--  {{config[0].notificationUrl}}  -->
    
    <h1><span i18n>Group Settings</span> : {{getGroup()}}</h1>
    <form [formGroup]="settingsForm" (submit)="submitForm()">
        
    <mat-form-field class="form-element">
        <mat-label><span i18n>Group Server Name</span></mat-label>
        <input type="text" matInput formControlName="serverName" placeholder="enter a server friendly name" />
        <mat-error *ngIf="settingsForm.get('serverName').hasError('required')">
            <span i18n>Server name is</span> <strong><span i18n>required</span></strong>
        </mat-error>
    </mat-form-field>

    <!-- choosing timeout -->  
    <div class="tip-modal">
        <div class="tip-amount">
            <!--
                <mat-label>Timeout Value in minuttes</mat-label>
                <input type="number" matInput formControlName="input_timeout" readonly />
                -->
            <table class="example-full-width" cellspacing="0">
                <tr>
                    <td>
                    <mat-label><span i18n>Timeout Value in minuttes</span> : </mat-label>      
                    </td>
                    <td>
                    <input type="number" matInput formControlName="input_timeout" readonly />   
                    </td>
                </tr>
            </table>
        </div>
        <div class="slider-wrapper">
            <input type="range" id="slider1" class="slider" #slider formControlName="timeout_slider" />
            <div class="circle1" id="circle1" #circle1></div>
            <div class="circle2" id="circle2" #circle2></div>
            <div class="circle3" id="circle3" #circle3></div>
            <div class="circle4" id="circle4" #circle4></div>
            <div class="circle5" id="circle5" #circle5></div>
        </div>
    </div>
    <br>
    <!-- choosing timeout -->  
    <div class="tip-modal">
        <div class="tip-amount">
            <table class="example-full-width" cellspacing="0">
                <tr>
                    <td>
                        <mat-label><span i18n>Trip Duration threshold in counts</span> :</mat-label>
                    </td>
                    <td>
                        <input type="number" matInput formControlName="input_duration" readonly />                    </td>
                </tr>
            </table>
                
              
        </div>
        <div class="slider-wrapper">
            <input type="range" id="slider2" class="slider" #slider formControlName="duration_slider" />
            <div class="circle1" id="circle1" #circle1></div>
            <div class="circle2" id="circle2" #circle2></div>
            <div class="circle3" id="circle3" #circle3></div>
            <div class="circle4" id="circle4" #circle4></div>
            <div class="circle5" id="circle5" #circle5></div>
        </div>
    </div>
    <br>
    <br>
    <br>
   

     <!-- Utc offset -->
    <mat-form-field class="form-element">
        <mat-label><span i18n>UTC offset</span></mat-label>
        <input
        type="number"
        matInput
        formControlName="utcOffset"
        placeholder="1"
        />
        <mat-error *ngIf="settingsForm.get('utcOffset').hasError('required')">
            <span i18n>UTC offset is</span> <strong><span i18n>required</span></strong>
        </mat-error>
    </mat-form-field>
    <section class="example-section" *ngFor="let flag of settingsFlagData">
        <mat-checkbox class="example-margin"
            (change)="onChangeEventFunc(flag.name, $event)">
        {{ flag.name }}
        </mat-checkbox>
    </section>
    <!-- ApiKey -->
    <mat-form-field class="form-element">
        <mat-label><span i18n>Server ApiKey</span></mat-label>
        <input
        type="text"
        matInput
        formControlName="apiKey"
        placeholder="xxA_key_xx"
        />
        <mat-error *ngIf="settingsForm.get('apiKey').hasError('required')">
            <span i18n>ApiKey is</span> <strong><span i18n>required</span></strong>
        </mat-error>
    </mat-form-field>
    <!-- Server Url -->
    <mat-form-field class="form-element">
        <mat-label><span i18n>Notification URL</span></mat-label>
        <input
        type="text"
        matInput
        formControlName="serverUrl"
        placeholder="http://serverUrl.com"
        />
        <mat-error *ngIf="settingsForm.get('serverUrl').hasError('required')">
            <span i18n>Notification URL is</span> <strong><span i18n>required</span></strong>
        </mat-error>
    </mat-form-field>

    <button
        [disabled]="!settingsForm.valid"
        mat-flat-button
        color="primary"
        type="submit"
        class="col-3 mt-2 mb-3"
    >
    <span i18n>Save</span>
    </button>
    <br />
    <ng-template [ngIf]="submitStatus">
        <span i18n>Form submitted succesfully!</span>
    </ng-template>
    </form>
</ul>  
</mat-card>