import { Component, OnInit, LOCALE_ID, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-help2",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent implements OnInit {
  languages = [
    { code: "en", label: "English" },
    { code: "da", label: "Danish" },
  ];

  constructor(
    private _router: Router,
    private cookieService: CookieService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  ngOnInit() {
    this.cookieService.set("last-page-visited", "help");
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/help"]);
  }
}
