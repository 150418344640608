<div class="container" *ngIf="!post; else forminfo" novalidate> 
  <form [formGroup]="newuserform" (ngSubmit)="onSubmit(newuserform.value)" class="form">
    
    <mat-form-field class="form-element">
      <mat-label><span i18n>User or Ressource name</span></mat-label>
      <input matInput placeholder="Name" formControlName="fullname">
      <mat-error *ngIf="!fullname.valid && fullname.touched">
        {{ titleAlert }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label><span i18n>Email</span></mat-label>
      <input matInput placeholder="Email address" formControlName="email">
      <mat-error *ngIf="!newuserform.controls['email'].valid && newuserform.controls['email'].touched">
        {{ getErrorEmail() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label>Group name<span i18n></span></mat-label>
      <input matInput placeholder="Group name" formControlName="groupname">
      <mat-hint>Admin is a reserved name.<span i18n></span></mat-hint>
      <mat-error *ngIf="!groupname.valid && groupname.touched">
         {{ titleAlert }}
      </mat-error>
    </mat-form-field>


    <mat-form-field class="form-element">
      <mat-label>Sensor code<span i18n></span></mat-label>
      <input matInput placeholder="Sensor Code" formControlName="UuidMajor">
    <!--
      <mat-error *ngIf="!uuidmajor.valid && uuidmajor.touched">
        {{ sensorCodeAlert }}
      </mat-error>
      -->
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label>Password<span i18n></span></mat-label>
      <input matInput placeholder="Password" formControlName="password">
      <mat-hint><span i18n>Password should be at least 8 characters. At least 1 capital letter and number</span></mat-hint>
      <mat-error *ngIf="!newuserform.controls['password'].valid && newuserform.controls['password'].touched">
        {{ getErrorPassword() }}
      </mat-error>
    </mat-form-field>

    <div class="form-element2">
      <mat-radio-group class="form-element2" formControlName="options">
        <mat-radio-button value="1"><span i18n>Standard User</span></mat-radio-button>&nbsp;
        <mat-radio-button  value="2"><span i18n>Ressource</span></mat-radio-button>&nbsp;
      </mat-radio-group>       
      <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!newuserform.valid"><span i18n>Save</span></button>
      <br>
 </div>
  </form>
</div>

<ng-template #forminfo>
  <div class="container">
    {{ post | json }}
  </div>
</ng-template>
