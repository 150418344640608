import { Component, OnInit, LOCALE_ID, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-clever",
  templateUrl: "./clever.component.html",
  styleUrls: ["./clever.component.scss"],
})
export class CleverComponent implements OnInit {
  averageKwhPriceIncludingVat = "";
  averageKwhPriceIncludingVatNight = "";
  averageKwhPrice = "";
  averageKwhPriceNight = "";
  month = "";
  refusionPrice = "";
  energyAddendum = "";
  EnergyPriceAddendumLimit = 0.89;
  averageNetTarif = 0.25;
  averageTarifNight = 0.0;
  systemTarif = 0.11;
  electricityTax = 0.01;
  myData;
  spotData = [];

  languages = [
    { code: "en", label: "English" },
    { code: "da", label: "Danish" },
  ];

  constructor(
    private _router: Router,
    private cookieService: CookieService,
    @Inject(LOCALE_ID) public localeId: string
  ) {}

  ngOnInit() {
    this.getAverageNetTarif();
    this.getAverageKwhPrice();

    // this.getSpotPricesNext24Hours();
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/clever"]);
  }
  getDay(dateString) {
    const date = new Date(dateString);
    const day = date.getDay();
    return day;
  }

  getHour(dateString) {
    const date = new Date(dateString);
    const day = date.getHours();
    return day;
  }

  getToDay() {
    const date = new Date();
    const day = date.getDay();
    return day;
  }

  getSpotPricesNext(data) {
    console.log("log");
    let yourDate = new Date();
    let todayString = yourDate.toISOString().split("T")[0];
    let tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    let tomorrowString = tommorow.toISOString().split("T")[0];
    let myHour = new Date().getHours();

    data.records.forEach((record) => {
      let hourData = {};
      if (record.PriceArea.includes("DK1")) {
        if (
          record.HourDK.includes(todayString) ||
          record.HourDK.includes(tomorrowString)
        ) {
          if (this.getDay(record.HourDK) !== this.getToDay()) {
            hourData["HourDK"] = record.HourDK;
            hourData["SpotPriceDKK"] = (record.SpotPriceDKK / 1000).toFixed(2);
            this.spotData.push(hourData);
          } else {
            if (this.getHour(record.HourDK) >= myHour) {
              hourData["HourDK"] = record.HourDK;
              hourData["SpotPriceDKK"] = (record.SpotPriceDKK / 1000).toFixed(
                2
              );
              this.spotData.push(hourData);
            }
          }
        }
      }
    });
    console.log(this.spotData);
    this.spotData.reverse();
  }
  getAverageNetTarif() {
    let averageTarifNight = [];
    let MyDate = new Date();
    let monthNumberString = "";
    monthNumberString = ("0" + (MyDate.getMonth() + 1)).slice(-2);
    let url =
      "https://api.energidataservice.dk/dataset/DatahubPricelist?offset=0&start=2023-{1}-15T00:00&end=2023-{2}-16T00:00&filter=%7B%22ChargeTypeCode%22:[%22CD%20R%22,%22CD%22,%22DT_C_01%22,%22RAH-C%22,%22151-NT01T%22,%2230TR_C_ET%22,%22TCL%5Cu003E100_02%22,%22C%22,%2246%22,%226000091%22,%22T3002%22,%22HEV-NT-01%22,%22NT-10%22,%22TNT1009%22,%220NCFF%22,%22TA031U200%22,%22Net%20C%22,%22IEV-NT-01%22,%22IEV-NT-11%22,%22FE2%20NT-01%22,%2243300%22,%2250001%22,%22K_22100%22,%22NT-C%22,%22TEV-NT-01%22,%2243110%22,%2294TR_C_ET%22,%224010%22],%22ChargeType%22:[%22D03%22]%7D&sort=ValidFrom%20ASC&timezone=dk";
    monthNumberString = "02";
    let url1 = url.replace("{1}", monthNumberString);
    let url2 = url1.replace("{2}", monthNumberString);

    //console.log(url2);
    fetch(url2)
      .then((response) => response.json())
      .then((data) => {
        //console.group("Data retrieved:");
        data.records.forEach((record) => {
          //console.log(record);
          if (record.Price24 !== null) {
            averageTarifNight.push(record.Price24);
          }
          if (record.Price1 !== null) {
            averageTarifNight.push(record.Price1);
          }
          if (record.Price2 !== null) {
            averageTarifNight.push(record.Price2);
          }
          if (record.Price3 !== null) {
            averageTarifNight.push(record.Price3);
          }
          if (record.Price4 !== null) {
            averageTarifNight.push(record.Price4);
          }
          if (record.Price5 !== null) {
            averageTarifNight.push(record.Price5);
          }
        });
        console.groupEnd();
        console.log(averageTarifNight.length);
        let sum = averageTarifNight.reduce(function (a, b) {
          return a + b;
        });
        let average = sum / averageTarifNight.length;
        console.log("Average Tarif");
        console.log(average);
        this.averageTarifNight = Number(average.toFixed(2));
      });
  }

  getAverageKwhPrice() {
    let spotPrices = [];
    let spotPricesNight = [];
    let date = new Date();
    //console.log(this.localeId);
    let myDate = "";
    if (this.localeId == "en") {
      myDate = date.toLocaleString("en-GB", { month: "long" });
    }
    if (this.localeId == "da") {
      myDate = date.toLocaleString("da-DK", { month: "long" });
    }

    this.month = myDate.charAt(0).toUpperCase() + myDate.slice(1);

    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let firstDayString = firstDay.toISOString();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let lastDayString = lastDay.toISOString();
    let firstDayString1 = firstDayString.substring(
      0,
      firstDayString.length - 13
    );
    let lastDayString1 = lastDayString.substring(0, lastDayString.length - 13);
    let firstDayString2 = firstDayString1 + "00:00";
    let lastDayString2 = lastDayString1 + "00:00";
    let url =
      "https://api.energidataservice.dk/dataset/Elspotprices?offset=0&start={1}&end={2}&filter=%7B%22PriceArea%22:[%22DK1%22,%22DK2%22]%7D&sort=HourUTC%20DESC&timezone=dk";
    let url1 = url.replace("{1}", firstDayString2);
    let url2 = url1.replace("{2}", lastDayString2);
    //console.log(url1);
    //console.log(url2);
    //Get specified amount of data from specified dataset
    fetch(url2)
      .then((response) => response.json())
      .then((data) => {
        //alert(`retrieved ${data.limit} of ${data.total}`);
        //console.group("Data retrieved:");
        this.getSpotPricesNext(data);
        data.records.forEach((record) => {
          //console.log(record);
          if (record.HourDK.includes("T23")) {
            spotPricesNight.push(record.SpotPriceDKK);
            //console.log("T23: " + record.SpotPriceDKK);
          }
          if (record.HourDK.includes("T00")) {
            spotPricesNight.push(record.SpotPriceDKK);
          }
          if (record.HourDK.includes("T01")) {
            spotPricesNight.push(record.SpotPriceDKK);
          }
          if (record.HourDK.includes("T02")) {
            spotPricesNight.push(record.SpotPriceDKK);
          }
          if (record.HourDK.includes("T03")) {
            spotPricesNight.push(record.SpotPriceDKK);
          }
          if (record.HourDK.includes("T04")) {
            spotPricesNight.push(record.SpotPriceDKK);
          }
          if (record.HourDK.includes("T05")) {
            spotPricesNight.push(record.SpotPriceDKK);
          }
          if (record.HourDK.includes("T06")) {
            spotPricesNight.push(record.SpotPriceDKK);
          }
          spotPrices.push(record.SpotPriceDKK);
        });
        console.groupEnd();
        //console.log(spotPrices);
        ///console.log(spotPricesNight);

        let sum = spotPrices.reduce(function (a, b) {
          return a + b;
        });
        let average = sum / spotPrices.length;
        let averageKwh = average / 1000;
        let averagKwhInclVat = averageKwh * 1.25;
        //console.log(averageKwh);

        let sumNight = spotPricesNight.reduce(function (a, b) {
          return a + b;
        });

        let averageNight = sumNight / spotPricesNight.length;
        let averageKwhNight = averageNight / 1000;
        let averagKwhInclVatNight = averageKwhNight * 1.25;
        //console.log(averagKwhInclVatNight);

        this.averageKwhPriceIncludingVat = String(averagKwhInclVat.toFixed(2));
        this.averageKwhPriceIncludingVatNight = String(
          averagKwhInclVatNight.toFixed(2)
        );

        this.averageKwhPrice = String(averageKwh);
        this.averageKwhPriceNight = String(averageKwhNight);
        let refusionPrice =
          (averageKwhNight +
            this.systemTarif +
            this.averageTarifNight +
            this.electricityTax) *
          1.25;
        this.refusionPrice = String(refusionPrice.toFixed(2));
        let energyPriceDelta = averagKwhInclVat - this.EnergyPriceAddendumLimit;
        this.energyAddendum = String(energyPriceDelta.toFixed(2));
      });
  }
}
