import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsCombinedComponent } from './settings/settings-combined.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatListModule} from '@angular/material/list';



@NgModule({
  declarations: [
    SettingsCombinedComponent
  ],
  imports: [
    CommonModule,
    //SettingsRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule,
    MatProgressSpinnerModule
  ]
})
export class SettingsModule { }
