<mat-card>
  <mat-card-title><span i18n>Groups</span></mat-card-title>
<mat-card>
   
  <ul *ngIf="groups$ | async; let groups" class="list-group">
    <mat-list>
      <mat-list-item *ngFor="let group of groups" >
        <p matLine>{{group}}</p>
        <a routerLink="/countbydate/{{group}}"><button mat-mini-fab color="primary" aria-labe="View Data">
          <mat-icon>visibility</mat-icon>
        </button></a>
        &nbsp;
        <a routerLink="/download/{{group}}"><button mat-mini-fab color="primary" aria-label="Download data">
          <mat-icon>save_alt</mat-icon>
        </button></a>
        &nbsp;
        <a routerLink="/combinedsettings/{{group}}">
          <button mat-mini-fab color="primary" aria-label="Edit Group">
            <mat-icon>create</mat-icon>
        </button></a>
        &nbsp;
        <a routerLink="/serversettings/{{group}}">
          <button mat-mini-fab color="primary" aria-label="Group Server Settings">
            <mat-icon>settings</mat-icon>
        </button></a>
      <!--  <mat-divider></mat-divider> -->
      </mat-list-item>
    </mat-list> 
  </ul>
</mat-card>
</mat-card>
<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner diameter="300"
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>
  
  