import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import SettingsJson from '../../../assets/appsettings.json';
import { of, from} from 'rxjs';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-about',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  appType$: Observable<string>;

  constructor(
    private _router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.cookieService.set('last-page-visited', 'privacy');
    let appType = environment.app_type
    console.log(appType)
    this.appType$ = of(appType);
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/privacy']);
  }

}


