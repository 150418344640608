import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "./../../auth/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { of } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"],
})
export class SupportComponent implements OnInit {
  resetPasswordForm;

  appMode$: Observable<string>;

  constructor(
    private _router: Router,
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private _snackBar: MatSnackBar
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: "",
    });
  }

  ngOnInit() {
    this.cookieService.set("last-page-visited", "support");
    let appMode = environment.app_mode;
    console.log(appMode);
    this.appMode$ = of(appMode);
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = "reload";
    this._router.navigate(["/support"]);
  }
  onSubmit(userData) {
    // Process checkout data here

    this.resetPasswordForm.reset();

    console.warn("Your order has been submitted", userData.email);
    this.authService.resetPasswordInit(userData.email);
    this.ResetReqSent();
  }

  ResetReqSent() {
    console.log("req sent");
    let message = "Nulstillings-request sendt ! ";
    this._snackBar.open(message, $localize`Undo`, {
      duration: 5000,
      verticalPosition: "top",
    });
  }
}
