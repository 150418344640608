<mat-card>
  <table mat-table id="excel-table" [hidden]="true" [dataSource]="tripsByDateAll$">
    <ng-container matColumnDef="driver">
        <th mat-header-cell *matHeaderCellDef><mat-icon class="icon commute" >owner</mat-icon></th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.ownerpresent ; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock> 
            <div>
              <span [ngStyle]="{'color':'green','font-weight':'bold'}">{{element.driver}}</span>
            </div>
          </ng-template>
          <ng-template #elseBlock>
            <div>
              <span [ngStyle]="{'color':'red','font-weight':'bold'}">{{element.driver}}</span>
            </div>
          </ng-template>
        </td>
    </ng-container>
    <ng-container matColumnDef="passenger1">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>1</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger1}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger1_email">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>1_email</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger1_email}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger2">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>2</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger2}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger2_email">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>2_email</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger2_email}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger3">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>3</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger3}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger3_email">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>3_email</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger3_email}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger4">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>4</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger4}} </td>
    </ng-container>
    <ng-container matColumnDef="passenger4_email">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon passenger" >person</mat-icon>4_email</th>
      <td mat-cell *matCellDef="let element"> {{element.passenger4_email}} </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon schedule" >date</mat-icon></th>
      <td mat-cell *matCellDef="let element" (click)="getTimeRecord(element)"> {{element.date}} </td> 
    </ng-container>
    <ng-container matColumnDef="starttime">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon schedule" >starttime</mat-icon></th>
      <td mat-cell *matCellDef="let element" (click)="getTimeRecord(element)"> {{element.starttime}} </td> 
    </ng-container>
    <ng-container matColumnDef="endtime">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon schedule" >endtime</mat-icon></th>
      <td mat-cell *matCellDef="let element" (click)="getTimeRecord(element)"> {{element.endtime}} </td> 
    </ng-container>
    <ng-container matColumnDef="ownerpresent">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon schedule" >owner-present</mat-icon></th>
      <td mat-cell *matCellDef="let element" (click)="getTimeRecord(element)"> {{element.ownerpresent}} </td> 
    </ng-container>
    <ng-container matColumnDef="carmodel">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon schedule" >car model</mat-icon></th>
      <td mat-cell *matCellDef="let element" (click)="getTimeRecord(element)"> {{element.carmodel}} </td> 
    </ng-container>
    <ng-container matColumnDef="licenseplate">
      <th mat-header-cell *matHeaderCellDef><mat-icon class="icon schedule" >license plate</mat-icon></th>
      <td mat-cell *matCellDef="let element" (click)="getTimeRecord(element)"> {{element.licenseplate}} </td> 
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
   <!--  <ng-container *ngIf=!appUserAgentCheck> -->
      <ng-container *ngIf=!isLoading>
        <a style="cursor: pointer" (click)="exportexcel()">
          <!-- <mat-icon> cloud_download</mat-icon>Download Excel -->
          <img class="image" mat-card-image src="assets/excel/Exel_download.svg" alt="photo">
        </a>
      </ng-container>
 <!--   </ng-container> -->
</mat-card>

<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner diameter="300"
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>

  


