import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TripByDate } from '../models/tripbydate';
import { TripByUser } from '../models/tripbyuser';
import SettingsJson from '../../../assets/appsettings.json';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TripByDateService {

  constructor(
    private http: HttpClient
    
  ) {
    console.log("constructor: ")
   }

  tripbydate$(url_param: string): Observable<TripByDate[]> { 
    let baseUrl = environment.userApi
    let tripByDateBaseUrl = baseUrl + SettingsJson.tripByDateUrl;
    console.log("tripByDateBaseUrl: " + tripByDateBaseUrl)
    return this.http.get<{daytrips_with_names: TripByDate[]}>(`${tripByDateBaseUrl}=${url_param}`).pipe(
      tap(val => console.log(`tripbydate BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result.daytrips_with_names)
        return result.daytrips_with_names;
      })
    );
  }
  
  alltripsbydate$(url_param: string): Observable<TripByDate[]> { 
    let baseUrl = environment.userApi
    let allTripsByDateBaseUrl = baseUrl + SettingsJson.allTripsByDateUrl;
    console.log("tripByDateBaseUrl: " + allTripsByDateBaseUrl)
    return this.http.get<{daytrips_with_names: TripByDate[]}>(`${allTripsByDateBaseUrl}=${url_param}`).pipe(
      tap(val => console.log(`alltripsbydate BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result.daytrips_with_names)
        return result.daytrips_with_names;
      })
    );
  }

  alltripsbyMyUser$(group_param: string, uid_param: string): Observable<TripByUser[]> { 
    let params = new HttpParams().set("group",group_param).set("userid", uid_param); //Create new HttpParams
    let baseUrl = environment.userApi
    let allTripsByMyUserBaseUrl = baseUrl + SettingsJson.allTripsByMyUserUrl;
    console.log("tripByMyUserBaseUrl: " + allTripsByMyUserBaseUrl)
    const fullURL = `${allTripsByMyUserBaseUrl}?${params.toString()}`;
    console.log({ fullURL });

    return this.http.get<{trips: TripByUser[]}>(`${fullURL}`).pipe(
      //tap(val => console.log(`alltripsbyMyUser BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result.trips)
        return result.trips;
      })
    );
  }

  
}
