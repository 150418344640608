<div>
    <form class="my-form" [formGroup]="form" (ngSubmit)="onSubmit()">
   
      <mat-error *ngIf="loginInvalid">
        <span i18n>User or Password not recognized</span>
      </mat-error>
      <mat-form-field class="full-width">
        <mat-label><span i18n>Email</span></mat-label>
        <input matInput  formControlName="username" required>
      </mat-form-field>
      <br>
      <mat-form-field class="full-width">
        <mat-label><span i18n>Password</span></mat-label>
        <input matInput type="password" formControlName="password" required>      
      </mat-form-field>
      <br>
      <div class="button-flex-container">
        <button mat-raised-button color="primary"><span i18n>Login</span></button>
      </div>
     
    </form>

</div>   
