import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Weather } from '../models/weather';
import SettingsJson from '../../../assets/appsettings.json';

export type NewUserSettingRequest = { 
  Active: string, 
  Options: string,
  CurrentUser: string, 
  Email: string, 
  Include: string, 
  Name: string, 
  SendEmail: string, 
  ShortName: string, 
  UserId: string, 
  TrafficLocation: string, 
  LicensePlate: string,
  CarModel: string, 
  Uuid: string, 
  UuidMajor: string, 
};

@Injectable({
  providedIn: 'root'
})
export class TrafficService {
    //How to make an angular module to ignore http interceptor added in a core module

  private httpClient: HttpClient;
  
  constructor(handler: HttpBackend,
    
    
  ) {
    console.log("constructor: ")
    this.httpClient = new HttpClient(handler);
    //console.log(SettingsJson);
   }
    
  weather$(lon:string, lat:string): Observable<any> { 
    //let lon = '9.7555486'
    //let lat = '57.1601539' 
    const params = new HttpParams()
    .set('lat', lat) 
    .set('lon', lon);

    const headerDict = {
      'Access-Control-Allow-Methods': 'GET',
    }
    
    const headers = new HttpHeaders(headerDict)

    const requestOptions = {                                                                                                                                                                              
      //headers, 
      params
    };

    let weatherUrl = 'https://api.met.no/weatherapi/nowcast/2.0/complete'; 
    console.log("weatherUrl: " + weatherUrl)  
    return this.httpClient.get(`${weatherUrl}`, requestOptions).pipe(
      tap(val => console.log(`weather BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result)
        return result;
      })

    );
  }

}
