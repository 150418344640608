import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TripsByCountComponent } from './trips/trips-by-count.component';
import { TripsByDateComponent } from './trips/trips-by-date.component';
import { TripsByDateAllComponent } from './trips/trips-by-date-all.component';
import { TripsByCountAllComponent } from './trips/trips-by-count-all.component';


const routes: Routes = [
  {path: 'countbyuser', component: TripsByCountComponent},
  {path: 'countbydate', component: TripsByDateComponent},
  {path: 'exceltripsbycount', component: TripsByCountAllComponent},
  {path: 'exceltripsbydate', component: TripsByDateAllComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule] 
})
export class TripsRoutingModule {

  constructor(

  ) {

  }

}
