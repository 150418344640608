<div mat-dialog-content style="text-align: center;">

  <h4><span i18n>Add trip</span></h4>

<form [formGroup]="form" (ngSubmit)="submitTrip()">
  <mat-form-field class="form-element">
    <mat-label><span i18n>Car</span></mat-label>
    <input matInput placeholder="Name" formControlName="Name">
  </mat-form-field>
 
  <label formArrayName="passengers" *ngFor="let passenger of passengersFormArray.controls; let i = index">
    <table>
      <tr>
      <td style="width:100px"><mat-checkbox class="example-margin" [formControlName]="i">{{passengersData[i].ShortName}} </mat-checkbox></td>
      </tr>
    </table>
  </label>
      
  <mat-divider></mat-divider>

  <br>
  <mat-form-field>
    <input matInput [matDatepicker]="picker" formControlName="startDate" [max]="maxDate" placeholder="{{placeholder}}">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="form-element">
    <mat-label><span i18n>Start time</span></mat-label>
    <input matInput placeholder="hh:mm" formControlName="StartTime">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label><span i18n>Stop time</span></mat-label>
    <input matInput placeholder="hh:mm" formControlName="StopTime">
  </mat-form-field>

  <div *ngIf="!form.valid"><span i18n>Trip form validation text</span></div>
  <br>
  <!-- At least one passenger must be selected + date, start and stop time -->
  <button mat-raised-button color="primary"  [disabled]="!form.valid"><span i18n>submit</span></button>

</form>

</div>