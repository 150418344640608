import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import SettingsJson from '../../../assets/appsettings.json';
import { environment } from '../../../environments/environment';

export type CreateUserRequest = { displayName: string, password: string, email: string, groups: string[], role: string };
export type UpdateUserRequest = { uid: string } & CreateUserRequest;
export type DeleteUserRequest = { uid: string };
export type EditUserRequest = { userId: string, shortName: string, uuidMajor: string };

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  get users$(): Observable<User[]> {
    console.log("users: ")
    let baseUrl = environment.userApi
    let usersBaseUrl = baseUrl + SettingsJson.usersUrl;
    console.log("usersBaseUrl: " + usersBaseUrl)
    return this.http.get<{ users: User[] }>(`${usersBaseUrl}`).pipe(
      map(result => {
        console.log(result.users)
        return result.users;
      })
    );
  }

  userEmails$(): Observable<string[]> {
    console.log("userEmails: ")
    let baseUrl = environment.userApi
    let usersBaseUrl = baseUrl + SettingsJson.usersUrl;
    console.log("usersBaseUrl: " + usersBaseUrl)
    return this.http.get<{ users: User[] }>(`${usersBaseUrl}`).pipe(
      map(result => {
        //console.log(result.users)
        var array1 = [];
        result.users.forEach(element => array1.push(element.email));
        return array1;
      })
    );
  }

  user$(id: string): Observable<User> {
    let baseUrl = environment.userApi
    let usersBaseUrl = baseUrl + SettingsJson.usersUrl;
    console.log("user$ usersBaseUrl: " + usersBaseUrl)
    return this.http.get<{ user: User }>(`${usersBaseUrl}/${id}`).pipe(
      map(result => {
        console.log(result.user)
        return result.user;
      })
    );
  }

  create(user: CreateUserRequest) {
    console.log("createUserRequest: " + JSON.stringify(user));
    let baseUrl = environment.userApi
    let usersBaseUrl = baseUrl + SettingsJson.usersUrl;
    console.log("usersBaseUrl: " + usersBaseUrl)
    return this.http.post(`${usersBaseUrl}`, user).pipe(
      //map(_ => { })
      map(result => {
        return result["uid"];
      })
    );
  }

  edit(user: UpdateUserRequest) {
    console.log("edit: " + JSON.stringify(user));
    let baseUrl = environment.userApi
    let usersBaseUrl = baseUrl + SettingsJson.usersUrl;
    console.log("usersBaseUrl: " + usersBaseUrl)
    return this.http.patch(`${usersBaseUrl}/${user.uid}`, user).pipe(
      map(_ => { })
    );
  }

  delete(user: DeleteUserRequest) {
    console.log("deleteUserRequest: " + user.uid);
    let baseUrl = environment.userApi
    let usersBaseUrl = baseUrl + SettingsJson.usersUrl;
    console.log("usersBaseUrl: " + usersBaseUrl)
    return this.http.delete(`${usersBaseUrl}/${user.uid}`).pipe(
      map(_ => { })
    );
  }

}
