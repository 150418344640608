  <div *ngIf="appType$ | async; let app_type">  
  <mat-card id="card" style="text-align: center;">
    <mat-card-title><span i18n>About</span></mat-card-title>
    <mat-grid-list cols="3" rowHeight="1:1.4">
      <mat-grid-tile>
        <img class="image" mat-card-image src="assets/faq_carpool.svg" alt="photo">   
      </mat-grid-tile>    
      <mat-grid-tile colsspan="2">
       
      </mat-grid-tile>
  </mat-grid-list>
  <span i18n>
    usertrack description
  </span>
  </mat-card>

</div>    

