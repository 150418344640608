import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { SettingsModule } from '../settings/settings.module';
import { MatCardModule } from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import { GoogleMapsTrafficComponent } from './traffic/google-maps-traffic.component';

@NgModule({
  declarations: [
    GoogleMapsTrafficComponent,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SettingsModule,
    MatCardModule,
    MatGridListModule
  ],
  exports: [
    GoogleMapsTrafficComponent,
  ],
})
export class GoogleMapsTrafficModule {}