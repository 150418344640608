<div *ngIf="appType$ | async; let app_type" class="list-group">
<div class="container" *ngIf="!post; else forminfo" novalidate> 
  <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form">
    <div *ngIf="app_type == 'carpool'">

      <mat-form-field class="form-element">
        <mat-label><span i18n>Name</span></mat-label>
        <input matInput placeholder="Name" formControlName="Name">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Short name / ID</span></mat-label>
        <input matInput placeholder="Short Name" minlength="1" maxlength="5" formControlName="ShortName">
      <!--  <mat-hint>Example: JD or B1 (max 3 letters)</mat-hint> -->
        <mat-error *ngIf="!shortname.valid && shortname.touched">
          {{ shortNameAlert }}
        </mat-error>
      </mat-form-field>
<!--

      <mat-form-field class="form-element">
        <mat-label><span i18n>Traffic Location</span></mat-label>
        <input matInput placeholder="Traffic Location" formControlName="TrafficLocation">
      </mat-form-field>
-->

      <mat-form-field class="form-element">
        <mat-label>Sensor code<span i18n></span></mat-label>
        <input matInput placeholder="Sensor Code" formControlName="UuidMajor">
        <!--   <mat-hint>Example: 64000</mat-hint> -->
        <mat-error *ngIf="!uuidmajor.valid && uuidmajor.touched">
          {{ sensorCodeAlert }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Licenseplate</span></mat-label>
        <input matInput placeholder="License Plate" minlength="7" maxlength="7" formControlName="LicensePlate">
      <!--    <mat-hint>Example: YY99888</mat-hint> -->
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Car model</span></mat-label>
        <input matInput placeholder="Car Model" minlength="1" maxlength="15" formControlName="CarModel">
      <!--     <mat-hint>Example: Peugeot 208</mat-hint> -->
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Last battery change date</span></mat-label>
        <input matInput placeholder="Battery change" minlength="1" maxlength="15" formControlName="BatteryChangeDate">
        <!--     <mat-hint>Example: Peugeot 208</mat-hint> -->
      </mat-form-field>
    </div>

    <div *ngIf="app_type == 'cartrack'">
      <mat-form-field class="form-element">
        <mat-label><span i18n>Name</span></mat-label>
        <input matInput placeholder="Name" formControlName="Name">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Short name / ID</span></mat-label>
        <input matInput placeholder="Short Name" minlength="1" maxlength="5" formControlName="ShortName">
      <!--  <mat-hint>Example: JD or B1 (max 3 letters)</mat-hint> -->
        <mat-error *ngIf="!shortname.valid && shortname.touched">
          {{ shortNameAlert }}
        </mat-error>
      </mat-form-field>

    <div *ngIf="usertype == '2'">
      <mat-form-field class="form-element">
        <mat-label><span i18n>Sensor code</span></mat-label>
       
        <input matInput [readonly] placeholder="Sensor Code" formControlName="UuidMajor">
        <!--   <mat-hint>Example: 64000</mat-hint> -->
        <mat-error *ngIf="!uuidmajor.valid && uuidmajor.touched">
          {{ sensorCodeAlert }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Licenseplate</span></mat-label>
        <input matInput placeholder="License Plate" minlength="7" maxlength="7" formControlName="LicensePlate">
      <!--    <mat-hint>Example: YY99888</mat-hint> -->
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Car model</span></mat-label>
        <input matInput placeholder="Car Model" minlength="1" maxlength="15" formControlName="CarModel">
      <!--     <mat-hint>Example: Peugeot 208</mat-hint> -->
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label><span i18n>Last battery change date</span></mat-label>
        <input matInput placeholder="Battery change" minlength="1" maxlength="15" formControlName="BatteryChangeDate">
        <!--     <mat-hint>Example: Peugeot 208</mat-hint> -->
      </mat-form-field>
    </div>
  </div>

    <div class="form-element">
      <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!form.valid"><span i18n>Save</span></button>
    </div>

  </form>
</div>
</div>

<ng-template #forminfo>
  <div class="container">
    {{ post | json }}
  </div>
</ng-template>
