import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import SettingsJson from "../../../assets/appsettings.json";
import { Convert, PowerData, PowerUsage } from "../models/power";

@Injectable({
  providedIn: "root",
})
export class PowerService {
  constructor(private _http: HttpClient) {
    console.log("PowerService constructor: ");
  }

  getdata(url_param: string) {
    let baseUrl = environment.userApi;
    let groupConfigBaseUrl = baseUrl + SettingsJson.powerUsageUrl;
    console.log("powerUsageBaseUrl: " + groupConfigBaseUrl);
    return this._http.get<PowerData>(`${groupConfigBaseUrl}=${url_param}`);
  }
}
